import React, { useRef, useState } from "react"
import { Helmet } from "react-helmet"
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa"
import Joi from "joi"
import Modal from "react-bootstrap/Modal"
import styles from "./SplashScreenv73.module.scss"

import { RouteComponentProps } from "react-router-dom"
import { getInvitor, subToNewsLetter } from "../../api"
import {
  storeInviteCode,
  getInviteCode,
} from "../../utils/state-management-utils"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

import playstore from "./../../assets/v6 wireframes/playStore.png"
import chamber from "./../../assets/chamber.png"
import appstore from "./../../assets/v6 wireframes/appStore.png"
import bobProctor from "./../../assets/bobProctor.png"
import victor from "./../../assets/victor.png"
import NatashaGraziano from "./../../assets/NatashaGraziano.png"
import johnAssaraf from "./../../assets/johnAssaraf.png"
import TerriLevine from "./../../assets/TerriLevine.png"
import ForbesRiley from "./../../assets/ForbesRiley.png"
import PinkyGhadiali from "./../../assets/PinkyGhadiali.png"
import EugenaOh from "./../../assets/EugenaOh.png"

// import CBCLogo from './../../assets/cbc.png'
// import FOXLogo from './../../assets/Mask Group.png'
// import NBCLogo from './../../assets/5aLogo.png'
// import PBSLogo from './../../assets/image 2 (1).png'
// import DJLLogo from './../../assets/Digital-Journal-Logo.png'
import Group2320 from "./../../assets/Group2320.png"

import CBCLogo from "./../../assets/CBS_logo.png"
import FOXLogo from "./../../assets/FOX_wordmark.png"
import NBCLogo from "./../../assets/NBC.png"
import PBSLogo from "./../../assets/PBS.png"
import DJLLogo from "./../../assets/Digital-Journal-Logo.png"

// import uniqueApp1 from './../../assets/newWireframe/Group 2345.png'
// import uniqueApp2 from './../../assets/newWireframe/Group 2347.png'
// import uniqueApp3 from './../../assets/newWireframe/Group 2348.png'
// import uniqueApp4 from './../../assets/newWireframe/Group 2349.png'
// import uniqueApp5 from './../../assets/newWireframe/Group 2350.png'

import uniqueApp1 from "./../../assets/newWireframe/friends profile.png"
import uniqueApp2 from "./../../assets/v6 wireframes/future state.png"
import uniqueApp3 from "./../../assets/v6 wireframes/notification (1).png"
import uniqueApp4 from "./../../assets/v6 wireframes/Feed - swipe left on post - audio tool tip.png"
import uniqueApp5 from "./../../assets/v6 wireframes/Goal Planner.png"
import uniqueApp6 from "./../../assets/v6 wireframes/Just goals.png"
import uniqueApp7 from "./../../assets/v6 wireframes/Conversation - accountability conversation.png"
import uniqueApp8 from "./../../assets/v6 wireframes/Page_4_WEBPAGE FOR SILVER CHALLENGE1 .png"
import uniqueApp88 from "./../../assets/v6 wireframes/Page_4_WEBPAGE FOR SILVER CHALLENGE111 .png"
import maskgroup from "./../../assets/newWireframe/Mask Group.png"

import chamberGray from "./../../assets/chamberGray.png"
import JohnAssaraf from "./../../assets/JA-LogoGray.png"
import mvhLogo from "./../../assets/mvh-logoGray.png"
import PGI from "./../../assets/PGI-Logo-300-169.png"
import forbes from "./../../assets/forbes.png"
import natasha from "./../../assets/natasha.png"
import HeartReprenour from "./../../assets/HeartReprenour.png"

import image1 from "./../../assets/newWireframe/Group 2355.gif"
import image2 from "./../../assets/newWireframe/Group 2258.png"
import image3 from "./../../assets/newWireframe/Group 2377.png"
import image4 from "./../../assets/newWireframe/Group 2354.png"
import slider1 from "./../../assets/newWireframe/Group 2361.png"
import slider2 from "./../../assets/newWireframe/Group 2364.png"
// import logov5 from './../../assetslogoV5.png'
import tigerImage from "./../../assets/newWireframe/tigerImage.png"
// import "./splashscreen.scss"
import { useSwipeable } from "react-swipeable"
// @ts-ignore
import Lottie from "react-lottie"
import fireBasket from "./../../assets/lottie/fireBasket.json"
import goalLottie from "./../../assets/lottie/Your_goal has zero setps.json"
import { getProfileImageSrc } from "../../utils/link-generator-utils"
import firstSecImage from "./../../assets/firstSec-New.png"

import quoteS from "./../../assets/quotes/VectorS.png"
import quote from "./../../assets/quotes/Vector.png"
import backgroundTribe from "./../../assets/BACKGROUNDtRIBE.png"

import "./SplashScreenv73.module.scss"
// import "./SplashScreen8.scss"

// import image from './../../assets/newWireframe/Group 2355.png'
interface PropsInterface
  extends RouteComponentProps<{ code?: string; uniqueSlider?: any }> {}

/**
 * This is the landing page. Show links to app store and list of features.
 */
// interface RouteParams<any>

export default class SplashScreenv8 extends React.Component<PropsInterface> {
  state = {
    hasInviteCode: false,
    inviter: {
      firstName: "",
      goalCount: "",
      invitorAvatar: "",
    },
    statusMessage: "",
    inviteCode: "",
    subscriberEmail: "",
    isTribe: false,
    tribeName: "",

    showModal: false,
    modalDialogue: {
      title: "",
      body: "",
    },
    nextScreenWF2: 1,
    uniqueText: {
      heading: `GET TO KNOW YOUR`,
      heading1: `FRIENDS EVEN BETTER`,
      p1: "Find out what's most important to them",
      p2: "Discover their goals listed by priority",
      p3: "",
    },
    translateZ: "translateZ(0)",
    defaultOptionsFire: {
      loop: true,
      autoplay: true,
      animationData: fireBasket,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    },
    defaultOptionsGoal: {
      loop: true,
      autoplay: true,
      animationData: goalLottie,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    },
    tapBool: false,
  }
  private uniqueSlider: React.RefObject<any>
  constructor(props: any) {
    super(props)

    // Create the ref
    // @ts-ignore
    this.uniqueSlider = React.createRef()
    // @ts-ignore
    this.uniqueSliderHeading = React.createRef()
  }
  componentDidMount() {
    const storedInviteCode = getInviteCode()
    if (this.props.match.params.code) {
      this.dispatchGetInvitor(this.props.match.params.code)
    } else if (storedInviteCode) {
      this.dispatchGetInvitor(storedInviteCode)
    }
  }

  async dispatchGetInvitor(code: string) {
    try {
      const respJson = await getInvitor({
        inviteCode: code,
      })
      const x = document.getElementById("root")

      this.setState({
        hasInviteCode: true,
        inviter: {
          firstName: respJson.data.name
            ? respJson.data.name.split(" ")[0]
            : respJson.data.tribeAdminName,
          goalCount: respJson.data?.goalCount ? respJson.data?.goalCount : 0,

          invitorAvatar: getProfileImageSrc(
            respJson.data.profile
              ? respJson.data.profile.image
              : respJson.data.tribe.picture
          ),
        },
        isTribe: respJson.data?.tribe && true,
        inviteCode: code,
        tribeName: respJson.data?.tribe?.name,
      })
      storeInviteCode(this.props.match.params.code)
    } catch (message) {
      this.setState({ statusMessage: message, success: false })
    }
  }

  NextScreen(number: number) {
    this.setState({ nextScreenWF2: number })
    // nextScreenWF2(number)
  }

  starSvg(num: number) {
    return (
      <div className={styles.v4ReviewStars}>
        {[...Array(num)].map((e, i) => {
          return (
            <svg
              key={`star-${i}`}
              version='1.1'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='currentColor'
            >
              <path d='M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z' />
            </svg>
          )
        })}
      </div>
    )
  }

  /*
      This method hadnles the subscription to newsletter 
      It also handles the modal display
    */
  subscribe = async () => {
    const schema = Joi.object({
      email: Joi.string().email({ tlds: { allow: false } }),
    })

    const { error } = schema.validate({
      email: this.state.subscriberEmail,
    })
    if (error) {
      return this.setState({
        ...this.state,
        showModal: true,
        modalDialogue: {
          title: "Error",
          body: error.message,
        },
      })
    }
    try {
      const result = await subToNewsLetter(this.state.subscriberEmail)
      this.setState({
        ...this.state,
        showModal: true,
        modalDialogue: {
          title: "Thank You",
          body: result,
        },
      })
    } catch (error) {
      this.setState({
        ...this.state,
        showModal: true,
        modalDialogue: {
          title: "Error",
          // @ts-ignore
          body: error.message,
        },
      })
    }
  }

  uniqueSliderHeadings = (i: number) => {
    let heading = ""
    let heading1 = ""
    let p1 = ""
    let p2 = ""
    let p3 = ""
    if (i === 0) {
      heading = "GET TO KNOW YOUR  "
      heading1 = "FRIENDS EVEN BETTER"
      p1 = "Find out what's most important to them"
      p2 = "Discover their goals listed by priority"
      p3 = ""
    } else if (i === 1) {
      heading = "CREATE MORE AUTHENTIC CONNECTIONS"
      heading1 = ""
      p1 = "Know who needs help and when"
      p2 = "Spread H.O.P.E: Help Other People Everyday"
      p3 = ""
    } else if (i === 2) {
      heading = "KEEP A PULSE ON WHAT "
      heading1 = "THEY NEED IN LIFE"
      p1 = "Pay it forward with one tap... "
      p2 = "in just a few minutes a day."
      p3 = ""
    } else if (i === 3) {
      heading = "HELPING LOVED ONES"
      heading1 = "JUST GOT EASIER"
      p1 = "Swipe left for video or voice comments"
      p2 = "Give encouragement, motivation,"
      p3 = "referrals, or advice"
    } else if (i === 4) {
      heading = "DREAM-STRETCHING "
      heading1 = "GOAL PLANNER"
      p1 = "Stretch your dreams, find new passions,"
      p2 = "Love life"
      p3 = ""
    } else if (i === 5) {
      heading = "SEE HOW OTHERS DO IT"
      heading1 = ""
      p1 = "Break goals into simple steps "
      p2 = "Build confidence as you make progress"
      p3 = ""
    } else if (i === 6) {
      heading = "MASTERMIND,"
      heading1 = "HOLD EACH OTHER TO IT"
      p1 = "World's Best Accountability Tool. EVER. "
      p2 = "Gets it done. Like never before. "
      p3 = ""
    } else if (i === 7) {
      heading = "EARN CASH AND REWARDS THROUGH IT"
      heading1 = ""
      p1 = "Delight in helping friends achieve more"
      p2 = ""
      p3 = ""
    } else {
      heading = `FIND OUT WHAT'S MOST IMPORTANT TO  YOUR FRIENDS`
      heading1 = ""
      p1 = "Discover their goals listed by priority"
      p2 = ""
      p3 = ""
    }

    this.setState({
      uniqueText: {
        heading: heading,
        heading1: heading1,
        p1: p1,
        p2: p2,
        p3: p3,
      },
      translateZ: "none",
    })

    setTimeout(() => {
      this.setState({
        translateZ: "translateZ(0)",
      })
    }, 100)

    // if (document.getElementById("uniqueApp1")
    //     || document.getElementById("uniqueApp2")
    //     || document.getElementById("uniqueApp3")
    //     || document.getElementById("uniqueApp4")
    //     || document.getElementById("uniqueApp5")
    //     || document.getElementById("uniqueApp6")
    //     || document.getElementById("uniqueApp7")
    //     || document.getElementById("uniqueApp8")) {
    //         console.log(document.getElementById("uniqueApp1"))
    //     // @ts-ignore
    //     document.getElementById("uniqueApp1").style.background = 'black'; // @ts-ignore
    //     // document.getElementById("uniqueApp2").style.transform = 'translateZ(0)'; // @ts-ignore
    //     // document.getElementById("uniqueApp3").style.transform = 'translateZ(0)'; // @ts-ignore
    //     // document.getElementById("uniqueApp4").style.transform = 'translateZ(0)'; // @ts-ignore
    //     // document.getElementById("uniqueApp5").style.transform = 'translateZ(0)'; // @ts-ignore
    //     // document.getElementById("uniqueApp6").style.transform = 'translateZ(0)'; // @ts-ignore
    //     // document.getElementById("uniqueApp7").style.transform = 'translateZ(0)'; // @ts-ignore
    //     // document.getElementById("uniqueApp8").style.transform = 'translateZ(0)'; // @ts-ignore
    // }
  }

  changeSlide = (element: any, id: string, navId: string) => {
    if (id === "slide2") {
      document.getElementById("slide1")?.classList.remove("active")
      document.getElementById(id)?.classList.add("active")
      document.getElementById("slide1Nav")?.classList.remove("navActive")
      document.getElementById(navId)?.classList.add("navActive")
    } else if (id === "slide1") {
      document.getElementById("slide2")?.classList.remove("active")
      document.getElementById(id)?.classList.add("active")
      document.getElementById("slide2Nav")?.classList.remove("navActive")
      document.getElementById(navId)?.classList.add("navActive")
    }
  }

  // text=()=>{
  //     console.log(this.stat)
  // }

  render() {
    var settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 2,
      centerMode: true,
      centerPadding: "0px",
      focusOnSelect: true,
      dotsClass: "reviewSlick slick-dots",
      autoPlay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "0px",
            respondTo: "min",
            variableWidth: true,
            focusOnSelect: false,
          },
        },
      ],
    }
    var sectionFifthSlider = {
      dots: true,
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      // variableWidth: true,
      slidesToScroll: 1,
    }

    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 3,
    //     slidesToScroll: 1

    //   };

    // let handlersSlider = useSwipeable ({
    //     onSwiped: (eventData) => console.log("User Swiped!", eventData)

    // });

    var settingsWireFrame = {
      dots: true,
      arrows: false,
      infinite: true,
      slidesToShow: 3,
      variableWidth: true,
      slidesToScroll: 2,
      centerMode: true,
      centerPadding: "0",
      autoplay: false,
      autoplaySpeed: 2000,
      draggable: true,
      // useTransform: false,
      afterChange: (currentIndex: any, currentSlide: any) => {
        this.uniqueSliderHeadings(currentIndex)
        console.log(currentSlide)
      },
      responsive: [
        {
          breakpoint: 2000,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            focusOnSelect: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            focusOnSelect: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "0px",
            respondTo: "min",
            focusOnSelect: false,
          },
        },
      ],
    }
    var settingsIamgesMob = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      // variableWidth: true,
      slidesToScroll: 1,
      centerMode: true,
      // centerPadding: "50px",
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
      ],
    }

    let headingslide = {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: true,
    }
    var inviteLink = `${this.state.inviter.firstName}  has shared ${this.state.inviter.goalCount} goal(s) with you`

    return (
      <div className={styles.v8}>
        <div className={styles.v5Main}>
          <Helmet>
            <title>
              #1 Goal Sharing Social Network - Delight in helping each other
              crush it | GoalMogul
            </title>
          </Helmet>
          {/* <div className={styles.v5Nav}>
                    <div>
                        <img src={logov5}/>
                    </div>
                </div> */}
          <div className={styles.v5MainInner}>
            {this.state.inviteCode ? (
              <div className={styles.v5HeadingInvite}>
                <div className={styles.imageHeader}>
                  <div className={styles.invitorAvatarBorder}>
                    <img
                      className={styles.invitorAvatarImage}
                      src={this.state.inviter.invitorAvatar}
                    />
                  </div>
                </div>
                {/* <br /> */}
                {!this.state.isTribe ? (
                  <div className={styles.v5Heading1}>
                    <h3 className={styles.inviteText}>
                      {this.state.inviter.firstName} has shared{" "}
                      {this.state.inviter.goalCount}{" "}
                      {Number(this.state.inviter.goalCount) > 1
                        ? `goals`
                        : `goal`}{" "}
                      with you
                    </h3>
                  </div>
                ) : (
                  <h3
                    className={styles.inviteText}
                    style={{ paddingBottom: 40 }}
                  >
                    {this.state.inviter.firstName} has invited you to join the{" "}
                    <br className='hideMob' />
                    {this.state.tribeName} Tribe!
                  </h3>
                )}
                {/* <h5 className={styles.customh5}>
                  {this.state.inviter.firstName} would love
                  <br /> your encouragement or suggestions.
                  <br /> To help with {this.state.inviter.firstName}'s goals,{" "}
                  <br />
                  join GoalMogul!
                </h5> */}
                {!this.state.isTribe ? (
                  <h5 className={styles.inviteh5}>
                    {this.state.inviter.firstName} would love your{" "}
                    <br className='hidePc' /> encouragement or suggestions
                    <br />
                    {/* <br className='hideMob' /> */}
                    <br className='hidePc' />
                    To help {this.state.inviter.firstName}'s goals, join
                    GoalMogul!
                  </h5>
                ) : (
                  <h5 className={styles.inviteh5}>
                    I’d like to keep up with your goals and share mine!
                    <br className='hideMob' />
                    <br className='hidePc' /> Come and connect with like-minded
                    achievers. <br className='hideMob' />{" "}
                    <br className='hideMob' />
                    <br />
                    {/* <br className='hideMob' /> */}
                    <br className='hidePc' />
                    GoalMogul gives us a fun and easy way to stay
                    <br className='hideMob' />
                    {` in touch, mastermind, and hold each other to account!`}
                  </h5>
                )}
                {/* <div className={styles.AppDownloadDiv}> */}
                {/* <div className={styles.buttonDown} style={{ backgroundImage: `url(${playstore})` }} > </div> 
              <div
                className={styles.buttonDown}
                style={{ backgroundImage: `url(${appstore})` }}
              >
                {" "}
              </div>{" "}
              */}
                {/* <a>
                                <img className={styles.buttonImage} src={playstore} />
                            </a>
                            <a>
                                <img className={styles.buttonImage} src={appstore} />
                            </a> */}
                {/* <h3 className={styles.v7ComingSoon}>COMING SOON</h3> */}
                {/* </div> */}
                <div className={styles.joingomo}>
                  <button
                    className={styles.joingomobutton}
                    onClick={(e) => {
                      this.props.history.push(
                        `/register/${this.state.inviteCode}`
                      )
                    }}
                  >
                    Join GoalMogul
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
            {/* <div className={styles.v7dot1FirstSec}> */}
            <div className={styles.v5Heading}>
              <h3 className={styles.backgroundLineImage}>
                Personal Development <br className='hidePc' /> Community{" "}
                <br className='hideMob' />
                for <br className='hidePc' /> Collaborative Achievement
                {/* Community for <br /> Personal Development Community */}
                {/* Goal Sharing <br />
                  Social Network for <br />
                  Overachievers <br /> */}
              </h3>

              {/* <h3>Goal Sharing <br className="hideMob" />Social Network <br className="hidePc" /> for  <br className="hidePc" /> Personal Empowerment</h3> */}
              {/* <h5>
                Delight in helping each other 
                </h5> */}
              <h5>
                Help people you care about <br />
                achieve the goals they care about.
                <br />
                <br />
                Delight in helping each other grow! <br className='hidePc' />
              </h5>
              <h6 className={styles.free100}>100% FREE</h6>
              <div className={styles.AppDownloadDiv}>
                {/* <div className={styles.buttonDown} style={{ backgroundImage: `url(${playstore})` }} > </div>
                            <div className={styles.buttonDown} style={{ backgroundImage: `url(${appstore})` }} > </div> */}
                <a
                  target='_blank'
                  href='https://play.google.com/store/apps/details?id=com.goalmogul.goalmogulbeta'
                >
                  <img className={styles.buttonImage} src={playstore} />
                </a>
                <a
                  target='_blank'
                  href='https://apps.apple.com/us/app/goalmogul/id1441503669'
                >
                  <img className={styles.buttonImage} src={appstore} />
                </a>

                {/* <h3 className={styles.v7ComingSoon}>COMING SOON</h3> */}
              </div>
            </div>
            <div className={styles.v5FirstSecImage}>
              <div style={{ backgroundImage: `url('${firstSecImage}')` }}>
                {/* <img src={firstSecImage} /> */}
              </div>
            </div>
          </div>
          {/* </div> */}
          <div className={styles.logoCon}>
            <div className={styles.innerLogoDivMob}>
              {/* <Slider  {...settingsMob}> */}
              <img src={CBCLogo} className={styles.cbcImage} />
              <img src={NBCLogo} className={styles.nbcImage} />
              <img src={DJLLogo} className={styles.DJImage} />
              <img src={FOXLogo} className={styles.foxImage} />
              <img src={PBSLogo} className={styles.pbsImage} />
              {/* <img src={NBCLogo} className={styles.nbcImage} />
                            <img src={FOXLogo} className={styles.foxImage} />
                            <img src={PBSLogo} className={styles.pbsImage} />
                            <img src={CBCLogo} className={styles.cbcImage} /> */}
              {/* </Slider> */}
            </div>
          </div>

          {/* E > div */}

          <div className='reviewSliderCon'>
            <div className='reviewSlider v8-Slider'>
              <Slider {...settings}>
                <div className='reviewBox'>
                  <div className='reviewSlide'>
                    <div>
                      <div className='reviewStars'>{this.starSvg(5)}</div>
                      <div className='reviewText'>
                        Sharing goals with friends is one of life’s greatest
                        keys to success. If you have unrealized dreams and
                        goals, then take a leap of faith and share them with the
                        world. This little known trick will help you manifest
                        and materialize your desires. GoalMogul has brilliantly
                        laid out a path that will nurture your mind for success.
                        Follow it and watch your dreams unfold and your
                        relationships blossom.
                      </div>
                    </div>
                    <div
                      className='revieImage'
                      onClick={() => {
                        window.open("https://markvictorhansen.com/", "_blank")
                      }}
                    >
                      <img src={victor} />
                    </div>
                    <div className='reviewerDetail'>
                      <h3
                        onClick={() => {
                          window.open("https://markvictorhansen.com/", "_blank")
                        }}
                      >
                        <b>Mark Victor Hansen</b>
                      </h3>
                      <p>
                        59x NY Times #1 Bestselling Author, 500 Million Books
                        Sold, Co-Creator of Chicken Soup Series
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className='reviewBox'>
                    <div className='reviewSlide'>
                      <div>
                        <div className='reviewStars'>{this.starSvg(5)}</div>
                        <div className='reviewText'>
                          If you want to elevate your life and business to the
                          next level, surround yourself with people who are
                          going to lift you higher. GoalMogul is the perfect
                          community for eliminating the noise and creating
                          synergies with those who are focused on personal
                          growth.
                        </div>
                      </div>
                      <div className='revieImage' onClick={() => {
                        window.open('https://natashagraziano.com/', '_blank')
                      }}>
                        <img src={NatashaGraziano} />
                      </div>
                      <div className='reviewerDetail'>
                        <h3 onClick={() => {
                          window.open('https://natashagraziano.com/', '_blank')
                        }}>
                          <b>Natasha Graziano</b>
                        </h3>
                        <p>
                          #1 Mindset Coach & Female Motivational Speaker Under
                          40 by Forbes Magazine, Yahoo and Huffington Post; #1
                          Bestselling Author and Law of Attraction Influencer
                          with Over 16 Million Social Media Followers & 1
                          Billion Views
                        </p>
                      </div>
                    </div>
                  </div> */}

                <div className='reviewBox'>
                  <div className='reviewSlide'>
                    <div>
                      <div className='reviewStars'>{this.starSvg(5)}</div>
                      <div className='reviewText'>
                        The people we associate with can make or break us.
                        GoalMogul offers a fertile and healthy environment where
                        you can truly surround yourself with like-minded
                        individuals who are interested in supporting you and
                        following their dreams.
                      </div>
                    </div>
                    <div
                      className='revieImage'
                      onClick={() => {
                        window.open(
                          "https://www.proctorgallagherinstitute.com/",
                          "_blank"
                        )
                      }}
                    >
                      <img src={bobProctor} />
                    </div>
                    <div className='reviewerDetail'>
                      <h3
                        onClick={() => {
                          window.open(
                            "https://www.proctorgallagherinstitute.com/",
                            "_blank"
                          )
                        }}
                      >
                        <b>Bob Proctor</b>
                      </h3>
                      <p>
                        Featured Teacher in 'The Secret' and Bestselling Author
                        of 'You Were Born Rich'
                      </p>
                    </div>
                    <div className='heartBobDiv'>
                      <div className='heartBob'>
                        <svg
                          width='24'
                          height='22'
                          viewBox='0 0 24 22'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M17.9828 0.261719C21.2947 0.261719 23.9766 2.94305 23.9766 6.24836C23.9766 12.235 17.9828 15.6437 11.9967 21.6304C6.01008 15.6437 0.0234375 12.235 0.0234375 6.24841C0.0234375 2.9431 2.70531 0.261774 6.01008 0.261774C9.00313 0.261774 10.4999 1.75857 11.9967 4.75162C13.493 1.75857 14.9898 0.261719 17.9828 0.261719Z'
                            fill='#FF7979'
                          />
                        </svg>
                      </div>
                      <p>
                        In Memoriam
                        <br />
                        7/5/34 - 2/3/22
                      </p>
                    </div>
                  </div>
                </div>
                <div className='reviewBox'>
                  <div className='reviewSlide'>
                    <div>
                      <div className='reviewStars'>{this.starSvg(5)}</div>
                      <div className='reviewText'>
                        Sharing rides and sharing homes revolutionized the world
                        by making traveling better. With GoalMogul, sharing
                        goals and helping each other will make your life better.
                        The possibilities are endless.
                      </div>
                    </div>
                    <div
                      className='revieImage'
                      onClick={() => {
                        window.open("https://www.myneurogym.com/", "_blank")
                      }}
                    >
                      <img src={johnAssaraf} />
                    </div>
                    <div className='reviewerDetail'>
                      <h3
                        onClick={() => {
                          window.open("https://www.myneurogym.com/", "_blank")
                        }}
                      >
                        <b>John Assaraf</b>
                      </h3>
                      <p>
                        Leading Brain & Achievement Expert, Thought-Leader on
                        The Ellen Degeneres Show, Anderson Cooper, and Larry
                        King Live
                      </p>
                    </div>
                  </div>
                </div>

                {/* <div className='reviewBox'>
                    <div className='reviewSlide'>
                      <div>
                        <div className='reviewStars'>{this.starSvg(5)}</div>
                        <div className='reviewText'>
                          If you want to elevate your life and business to the
                          next level, surround yourself with people who are
                          going to lift you higher. GoalMogul is the perfect
                          community for eliminating the noise and creating
                          synergies with those who are focused on personal
                          growth.
                        </div>
                      </div>
                      <div className='revieImage'>
                        <img src={NatashaGraziano} />
                      </div>
                      <div className='reviewerDetail'>
                        <h3>
                          <b>Natasha Graziano</b>
                        </h3>
                        <p>
                          #1 Mindset Coach & Female Motivational Speaker Under
                          40 by Forbes Magazine, Yahoo and Huffington Post; #1
                          Bestselling Author and Law of Attraction Influencer
                          with Over 16 Million Social Media Followers & 1
                          Billion Views
                        </p>
                      </div>
                    </div>
                  </div> */}
                <div className='reviewBox'>
                  <div className='reviewSlide'>
                    <div>
                      <div className='reviewStars'>{this.starSvg(5)}</div>
                      <div className='reviewText'>
                        Sharing goals with friends is one of life’s greatest
                        keys to success. If you have unrealized dreams and
                        goals, then take a leap of faith and share them with the
                        world. This little known trick will help you manifest
                        and materialize your desires. GoalMogul has brilliantly
                        laid out a path that will nurture your mind for success.
                        Follow it and watch your dreams unfold and your
                        relationships blossom.
                      </div>
                    </div>
                    <div
                      className='revieImage'
                      onClick={() => {
                        window.open("https://markvictorhansen.com/", "_blank")
                      }}
                    >
                      <img src={victor} />
                    </div>
                    <div className='reviewerDetail'>
                      <h3
                        onClick={() => {
                          window.open("https://markvictorhansen.com/", "_blank")
                        }}
                      >
                        <b>Mark Victor Hansen</b>
                      </h3>
                      <p>
                        59x NY Times #1 Bestselling Author, 500 Million Books
                        Sold, Co-Creator of Chicken Soup Series
                      </p>
                    </div>
                  </div>
                </div>
                <div className='reviewBox'>
                  <div className='reviewSlide'>
                    <div>
                      <div className='reviewStars'>{this.starSvg(5)}</div>
                      <div className='reviewText'>
                        The people we associate with can make or break us.
                        GoalMogul offers a fertile and healthy environment where
                        you can truly surround yourself with like-minded
                        individuals who are interested in supporting you and
                        following their dreams.
                      </div>
                    </div>
                    <div
                      className='revieImage'
                      onClick={() => {
                        window.open(
                          "https://www.proctorgallagherinstitute.com/",
                          "_blank"
                        )
                      }}
                    >
                      <img src={bobProctor} />
                    </div>
                    <div
                      className='reviewerDetail'
                      onClick={() => {
                        window.open(
                          "https://www.proctorgallagherinstitute.com/",
                          "_blank"
                        )
                      }}
                    >
                      <h3>
                        <b>Bob Proctor</b>
                      </h3>
                      <p>
                        Featured Teacher in 'The Secret' and Bestselling Author
                        of 'You Were Born Rich'
                      </p>
                    </div>
                    <div className='heartBobDiv'>
                      <div className='heartBob'>
                        <svg
                          width='24'
                          height='22'
                          viewBox='0 0 24 22'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M17.9828 0.261719C21.2947 0.261719 23.9766 2.94305 23.9766 6.24836C23.9766 12.235 17.9828 15.6437 11.9967 21.6304C6.01008 15.6437 0.0234375 12.235 0.0234375 6.24841C0.0234375 2.9431 2.70531 0.261774 6.01008 0.261774C9.00313 0.261774 10.4999 1.75857 11.9967 4.75162C13.493 1.75857 14.9898 0.261719 17.9828 0.261719Z'
                            fill='#FF7979'
                          />
                        </svg>
                      </div>
                      <p>
                        In Memoriam
                        <br />
                        7/5/34 - 2/3/22
                      </p>
                    </div>
                  </div>
                </div>
                <div className='reviewBox'>
                  <div className='reviewSlide'>
                    <div>
                      <div className='reviewStars'>{this.starSvg(5)}</div>
                      <div className='reviewText'>
                        Sharing rides and sharing homes revolutionized the world
                        by making traveling better. With GoalMogul, sharing
                        goals and helping each other will make your life better.
                        The possibilities are endless.
                      </div>
                    </div>
                    <div
                      className='revieImage'
                      onClick={() => {
                        window.open("https://www.myneurogym.com/", "_blank")
                      }}
                    >
                      <img src={johnAssaraf} />
                    </div>
                    <div
                      className='reviewerDetail'
                      onClick={() => {
                        window.open("https://www.myneurogym.com/", "_blank")
                      }}
                    >
                      <h3>
                        <b>John Assaraf</b>
                      </h3>
                      <p>
                        Leading Brain & Achievement Expert, Thought-Leader on
                        The Ellen Degeneres Show, Anderson Cooper, and Larry
                        King Live
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>

          <div className='reviewSliderCon secondReviewSliderCon'>
            <div className='reviewSlider v8-Slider'>
              <Slider {...settings}>
                {/* <div className='reviewBox'>
                    <div className='reviewSlide'>
                      <div>
                        <div className='reviewStars'>{this.starSvg(5)}</div>
                        <div className='reviewText'>
                          I have always been a huge fan of GoalMogul. If you
                          want to know whether or not playing full out on
                          GoalMogul will improve your life, productivity, and
                          connections... the answer is YES, YES, and YES!
                        </div>
                      </div>
                      <div className='revieImage'>
                        <img src={ForbesRiley} />
                      </div>
                      <div className='reviewerDetail'>
                        <h3>
                          <b>Dr. Forbes Riley</b>
                        </h3>
                        <p>
                          Top 20 Most Inspiring People on TV', Celebrity Host,
                          Bestselling Author, Motivational Speaker,
                          Megainfluencer with 2M+ Social Media Followers, and
                          'Queen of the Perfect Pitch' with $2.5B+ in Product
                          Sales
                        </p>
                      </div>
                    </div>
                  </div> */}

                <div className='reviewBox'>
                  <div className='reviewSlide'>
                    <div>
                      <div className='reviewStars'>{this.starSvg(5)}</div>
                      <div className='reviewText'>
                        Our done-for-you marketing agency for coaches has served
                        6200+ business owners and built and sold multiple
                        million-dollar businesses in the last 30 years. As a
                        consultant to thousands, it's my job to keep a pulse on
                        the evolving goals and needs of my audience. GoalMogul
                        makes it fun and really easy to keep up with my crowd.
                        Never before have I seen such an effective
                        relationship-building tool for helping me deliver even
                        more impact.
                      </div>
                    </div>
                    <div
                      className='revieImage'
                      onClick={() => {
                        window.open("https://heartrepreneur.com/", "_blank")
                      }}
                    >
                      <img src={TerriLevine} />
                    </div>
                    <div className='reviewerDetail'>
                      <h3
                        onClick={() => {
                          window.open("https://heartrepreneur.com/", "_blank")
                        }}
                      >
                        <b>Dr. Terri Levine</b>
                      </h3>
                      <p>
                        #1 Bestselling Author of 40+ Books, TEDx keynote
                        speaker, podcast host, & Founder of Heartrepreneur
                        Agency, featured on ABC, NBC, CBS & CNBC
                      </p>
                    </div>
                  </div>
                </div>

                <div className='reviewBox'>
                  <div className='reviewSlide'>
                    <div>
                      <div className='reviewStars'>{this.starSvg(5)}</div>
                      <div className='reviewText'>
                        Your greatest friends in life are those who want to help
                        you be your best. GoalMogul has a really great vibe --
                        it's awesome because it makes it so easy to connect with
                        lots of great new friends who are encouraging and
                        resourceful... I'm super-pumped because they all want
                        the best for you!
                      </div>
                    </div>
                    <div className='revieImage'>
                      <img src={EugenaOh} />
                    </div>
                    <div className='reviewerDetail'>
                      <h3>
                        <b>Eugena Oh</b>
                      </h3>
                      <p>
                        President & CEO of The National "I Have A Dream”
                        Foundation, Board Director for University of
                        Pennsylvania's Alumni Board, and NY Non-Profit Media’s
                        40 Under 40 Rising Stars
                      </p>
                    </div>
                  </div>
                </div>

                <div className='reviewBox'>
                  <div className='reviewSlide'>
                    <div>
                      <div className='reviewStars'>{this.starSvg(5)}</div>
                      <div className='reviewText'>
                        Every great networker knows that it's all about finding
                        the best way to serve those you meet. If you have a
                        heart for helping others grow, GoalMogul is the
                        community that's perfect for you.
                      </div>
                    </div>
                    <div
                      className='revieImage'
                      onClick={() => {
                        window.open("https://www.netwomen.co/", "_blank")
                      }}
                    >
                      <img src={PinkyGhadiali} />
                    </div>
                    <div className='reviewerDetail'>
                      <h3
                        onClick={() => {
                          window.open("https://www.netwomen.co/", "_blank")
                        }}
                      >
                        <b>Pinky Ghadiali</b>
                      </h3>
                      <p>
                        Master NLP Practitioner and Founder of Netwomen.co - The
                        Global Personal Development and Mastermind Network for
                        Ambitious Women
                      </p>
                    </div>
                  </div>
                </div>

                {/* <div className='reviewBox'>
                    <div className='reviewSlide'>
                      <div>
                        <div className='reviewStars'>{this.starSvg(5)}</div>
                        <div className='reviewText'>
                          I have always been a huge fan of GoalMogul. If you
                          want to know whether or not playing full out on
                          GoalMogul will improve your life, productivity, and
                          connections... the answer is YES, YES, and YES!
                        </div>
                      </div>
                      <div className='revieImage'>
                        <img src={ForbesRiley} />
                      </div>
                      <div className='reviewerDetail'>
                        <h3>
                          <b>Dr. Forbes Riley</b>
                        </h3>
                        <p>
                          Top 20 Most Inspiring People on TV', Celebrity Host,
                          Bestselling Author, Motivational Speaker,
                          Megainfluencer with 2M+ Social Media Followers, and
                          'Queen of the Perfect Pitch' with $2.5B+ in Product
                          Sales
                        </p>
                      </div>
                    </div>
                  </div> */}

                <div className='reviewBox'>
                  <div className='reviewSlide'>
                    <div>
                      <div className='reviewStars'>{this.starSvg(5)}</div>
                      <div className='reviewText'>
                        Our done-for-you marketing agency for coaches has served
                        6200+ business owners and built and sold multiple
                        million-dollar businesses in the last 30 years. As a
                        consultant to thousands, it's my job to keep a pulse on
                        the evolving goals and needs of my audience. GoalMogul
                        makes it fun and really easy to keep up with my crowd.
                        Never before have I seen such an effective
                        relationship-building tool for helping me deliver even
                        more impact.
                      </div>
                    </div>
                    <div
                      className='revieImage'
                      onClick={() => {
                        window.open("https://heartrepreneur.com/", "_blank")
                      }}
                    >
                      <img src={TerriLevine} />
                    </div>
                    <div className='reviewerDetail'>
                      <h3
                        onClick={() => {
                          window.open("https://heartrepreneur.com/", "_blank")
                        }}
                      >
                        <b>Dr. Terri Levine</b>
                      </h3>
                      <p>
                        #1 Bestselling Author of 40+ Books, TEDx keynote
                        speaker, podcast host, & Founder of Heartrepreneur
                        Agency, featured on ABC, NBC, CBS & CNBC
                      </p>
                    </div>
                  </div>
                </div>

                <div className='reviewBox'>
                  <div className='reviewSlide'>
                    <div>
                      <div className='reviewStars'>{this.starSvg(5)}</div>
                      <div className='reviewText'>
                        Your greatest friends in life are those who want to help
                        you be your best. GoalMogul has a really great vibe --
                        it's awesome because it makes it so easy to connect with
                        lots of great new friends who are encouraging and
                        resourceful... I'm super-pumped because they all want
                        the best for you!
                      </div>
                    </div>
                    <div className='revieImage'>
                      <img src={EugenaOh} />
                    </div>
                    <div className='reviewerDetail'>
                      <h3>
                        <b>Eugena Oh</b>
                      </h3>
                      <p>
                        President & CEO of The National "I Have A Dream”
                        Foundation, Board Director for University of
                        Pennsylvania's Alumni Board, and NY Non-Profit Media’s
                        40 Under 40 Rising Stars
                      </p>
                    </div>
                  </div>
                </div>

                <div className='reviewBox'>
                  <div className='reviewSlide'>
                    <div>
                      <div className='reviewStars'>{this.starSvg(5)}</div>
                      <div className='reviewText'>
                        Every great networker knows that it's all about finding
                        the best way to serve those you meet. If you have a
                        heart for helping others grow, GoalMogul is the
                        community that's perfect for you.
                      </div>
                    </div>
                    <div
                      className='revieImage'
                      onClick={() => {
                        window.open("https://www.netwomen.co/", "_blank")
                      }}
                    >
                      <img src={PinkyGhadiali} />
                    </div>
                    <div className='reviewerDetail'>
                      <h3
                        onClick={() => {
                          window.open("https://www.netwomen.co/", "_blank")
                        }}
                      >
                        <b>Pinky Ghadiali</b>
                      </h3>
                      <p>
                        Master NLP Practitioner and Founder of Netwomen.co - The
                        Global Personal Development and Mastermind Network for
                        Ambitious Women
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>

          <div className='uniqueAppSec'>
            <div className='innerUniqueSec'>
              {/* @ts-ignore */}
              {/* <Slider ref={slider => (this.uniqueSliderHeading = slider)} {...headingslide} asNavFor={this.uniqueSlider}> */}

              <div className='uniqueText'>
                <h2>
                  {this.state.uniqueText.heading}{" "}
                  {this.state.uniqueText.heading1 ? (
                    <>
                      {" "}
                      <br className='hidePc' /> {this.state.uniqueText.heading1}{" "}
                    </>
                  ) : (
                    ""
                  )}
                </h2>
                <p>
                  {this.state.uniqueText.p1}
                  {this.state.uniqueText.p2 ? (
                    <>
                      <br /> {this.state.uniqueText.p2}
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.uniqueText.p3 ? (
                    <>
                      {" "}
                      <br className='hidePc' />
                      {this.state.uniqueText.p3}{" "}
                    </>
                  ) : (
                    ""
                  )}{" "}
                  <br className='hidePc' /> <br className='hidePc' />{" "}
                </p>
              </div>
              {/* <div>
                                    <h2>CREATE MORE AUTHENTIC CONNECTIONS</h2>
                                    <p>Spread H.O.P.E:<br/>
                                    Help Other People Everyday</p>
                                </div>
                                <div>
                                    <h2>KEEP A PULSE ON WHAT THEY NEED IN LIFE</h2>
                                    <p>Pay it forward with one tap... in just a few minutes a day.</p>
                                </div>
                                <div>
                                    <h2>HELPING FRIENDS JUST GOT EASIER</h2>
                                    <p>Swipe left for video or voice comments<br/>
                                    Give encouragement, motivation, referrals, or advice</p>
                                </div>
                                <div>
                                    <h2>DREAM-STRETCHING GOAL PLANNER</h2>
                                    <p>Stretch your dreams, find new passions,<br/>
                                    Love life</p>
                                </div>
                                <div>
                                    <h2>SEE HOW OTHERS DO IT</h2>
                                    <p>Break goals into simple steps <br/>
                                    Build confidence as you make progress</p>
                                </div>
                                <div>
                                    <h2>MASTERMIND, HOLD EACH OTHER TO IT</h2>
                                    <p>World's Best Accountability Tool. EVER. <br/>
                                    Gets it done. Like never before. </p>
                                </div>
                                <div>
                                    <h2>EARN CASH AND REWARDS THROUGH IT</h2>
                                    <p>Delight in helping friends achieve more</p>
                                </div> */}
              {/* </Slider> */}
              <div className='uniqueAppSlider'>
                {/* <Slider {...settingsWireFrame}>
                                    <div className="wireframeDiv">
                                        <div className="wireframe">
                                            <img src={uniqueApp1} />
                                        </div>
                                    </div>
                                    <div className="wireframeDiv">
                                        <div className="wireframe">
                                            <img src={uniqueApp2} />
                                        </div>
                                    </div>
                                    <div className="wireframeDiv">
                                        <div className="wireframe">
                                            <img src={uniqueApp3} />
                                        </div>
                                    </div>
                                    <div className="wireframeDiv">
                                        <div className="wireframe">
                                            <img src={uniqueApp4} />
                                        </div>
                                    </div>
                                    <div className="wireframeDiv">
                                        <div className="wireframe">
                                            <img src={uniqueApp5} />
                                        </div>
                                    </div>
                                </Slider> */}
                {/* @ts-ignore */}
                <Slider
                  ref={(slider: any) => {
                    // @ts-ignore
                    return (this.uniqueSlider = slider)
                  }}
                  {...settingsWireFrame}
                >
                  <div className='wireframeDiv'>
                    <div className='wireframe'>
                      <img
                        src={uniqueApp1}
                        className='backMask'
                        style={
                          this.state.translateZ
                            ? { transform: this.state.translateZ }
                            : { transform: "none" }
                        }
                      />
                      <img src={maskgroup} className='maskImage hidePc' />
                    </div>
                  </div>
                  <div className='wireframeDiv'>
                    <div className='wireframe'>
                      <img
                        src={uniqueApp2}
                        className='backMask'
                        style={
                          this.state.translateZ
                            ? { transform: this.state.translateZ }
                            : { transform: "none" }
                        }
                      />
                      <img src={maskgroup} className='maskImage hidePc' />
                    </div>
                  </div>
                  <div className='wireframeDiv'>
                    <div className='wireframe'>
                      <img
                        src={uniqueApp3}
                        className='backMask'
                        style={
                          this.state.translateZ
                            ? { transform: this.state.translateZ }
                            : { transform: "none" }
                        }
                      />
                      <img src={maskgroup} className='maskImage hidePc' />
                    </div>
                  </div>
                  <div className='wireframeDiv'>
                    <div className='wireframe'>
                      <img
                        src={uniqueApp4}
                        className='backMask'
                        style={
                          this.state.translateZ
                            ? { transform: this.state.translateZ }
                            : { transform: "none" }
                        }
                      />
                      <img src={maskgroup} className='maskImage hidePc' />
                    </div>
                  </div>
                  <div className='wireframeDiv'>
                    <div className='wireframe'>
                      <img
                        src={uniqueApp5}
                        className='backMask'
                        style={
                          this.state.translateZ
                            ? { transform: this.state.translateZ }
                            : { transform: "none" }
                        }
                      />
                      <img src={maskgroup} className='maskImage hidePc' />
                    </div>
                  </div>
                  <div className='wireframeDiv'>
                    <div className='wireframe'>
                      <img
                        src={uniqueApp6}
                        className='backMask'
                        style={
                          this.state.translateZ
                            ? { transform: this.state.translateZ }
                            : { transform: "none" }
                        }
                      />
                      <img src={maskgroup} className='maskImage hidePc' />
                    </div>
                  </div>
                  <div className='wireframeDiv'>
                    <div className='wireframe'>
                      <img
                        src={uniqueApp7}
                        className='backMask'
                        style={
                          this.state.translateZ
                            ? { transform: this.state.translateZ }
                            : { transform: "none" }
                        }
                      />
                      <img src={maskgroup} className='maskImage hidePc' />
                    </div>
                  </div>
                  <div className='wireframeDiv'>
                    <div className='wireframe'>
                      <img
                        src={uniqueApp8}
                        className='backMask'
                        style={
                          this.state.translateZ
                            ? { transform: this.state.translateZ }
                            : { transform: "translateZ(0)" }
                        }
                      />
                      <img src={maskgroup} className='maskImage hidePc' />
                    </div>
                  </div>
                </Slider>
                <div className='mobFrame mobHide'>
                  <img src={maskgroup} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.reviewerLogo}>
            <div className={styles.innerReviewLogo}>
              <div className={styles.chmaberImageReviewLogoDiv}>
                <img className={styles.chmaberImage} src={chamberGray} />
              </div>

              <div
                className={styles.pgiImageReviewLogoDiv}
                onClick={() => {
                  window.open(
                    "https://www.proctorgallagherinstitute.com/",
                    "_blank"
                  )
                }}
              >
                <img className={styles.pgiImage} src={PGI} />
              </div>

              <div
                className={styles.johnImageReviewLogoDiv}
                onClick={() => {
                  window.open("https://www.myneurogym.com/", "_blank")
                }}
              >
                <img className={styles.johnImage} src={JohnAssaraf} />
              </div>

              <div
                className={styles.mvhImageReviewLogoDiv}
                onClick={() => {
                  window.open("https://markvictorhansen.com/", "_blank")
                }}
              >
                <img className={styles.mvhImage} src={mvhLogo} />
              </div>

              {/* <div className={styles.forbesReviewLogoDiv}>
                  <img className={styles.forbes} src={forbes} />
                </div> */}

              {/* <div className={styles.natashaReviewLogoDiv} onClick={() => {
                  window.open('https://natashagraziano.com/', '_blank')
                }}>
                  <img className={styles.natasha} src={natasha} />
                </div> */}

              <div
                className={styles.HeartReprenourReviewLogoDiv}
                onClick={() => {
                  window.open("https://heartrepreneur.com/", "_blank")
                }}
              >
                <img className={styles.HeartReprenour} src={HeartReprenour} />
              </div>
            </div>
          </div>
          <div className='backgroundWhite  '>
            <div className='sectionFourth splashScreen7-2Fourth'>
              <div className='wireframeText colRev'>
                <div className='textWire'>
                  <h2>
                    Achieve More with <br className='hideMob' />
                    Accountability
                  </h2>
                  <p>
                    Set empowering goals. <br />
                    Share them with encouraging, like-minded{" "}
                    <br className='hideMob' />
                    possibility thinkers!
                  </p>
                </div>
                <div className='imgWire'>
                  <img src={image1} />
                  {/* <div className="wireframeImage" style={{ backgroundImage: `url('${image1}')` }}></div> */}
                  {/* <div className='lottieDiv'> */}
                  {/* <Lottie
                      options={this.state.defaultOptionsFire}
                      //    height={400}
                      //    width={400}
                    /> */}
                  {/* </div> */}
                </div>
              </div>
              <div className='stripe'>
                <div className='innerStripe'>
                  <img className='quoteS' src={quoteS} />
                  <h3>
                    Accountability is the glue that ties commitment to the
                    result.
                  </h3>
                  <div className='byName'>
                    <p>Bob Proctor</p>
                  </div>
                  <img className='quote' src={quote} />
                </div>
              </div>
              <div className='wireframeText'>
                <div className='imgWire'>
                  <img src={image2} />
                  {/* <div className="wireframeImage" style={{ backgroundImage: `url('${image2}')` }}></div> */}
                </div>
                <div className='textWire'>
                  <h2>
                    Think Big and Kick Ass with
                    <br className='hideMob' /> the World's Most Powerful <br />{" "}
                    Goal Planner
                  </h2>
                  <p>
                    Find out how friends would <br className='hidePc' />
                    answer questions like these <br />
                    Stretch your dreams, find greater purpose
                    <br />
                    Get proven strategies to crush self-doubt
                  </p>
                </div>
              </div>
              <div className='stripe'>
                <div className='innerStripe'>
                  <img className='quoteS' src={quoteS} />
                  <h3>
                    Share your goals with others. You can help motivate each
                    other <br className='hideMob' />
                    further and hold each other to account.
                  </h3>
                  <div className='byName'>
                    <p>
                      Sir Richard Branson (‘how to make goals and{" "}
                      <br className='hidePc' /> stick to them”, 2020)
                    </p>
                  </div>
                  <img className='quote' src={quote} />
                </div>
              </div>
              <div className='wireframeText wireframeText3 colRev '>
                <div className='textWire'>
                  <h2>
                    Your Future <br className={styles.hideMob} /> in Your Hands,
                    with
                    <br className={styles.hideMob} /> Friends Supporting Each
                    Other
                  </h2>
                  <p>
                    Create a clear roadmap for what you want <br />
                    Get reminders and inspiration
                    <br />
                    Grow closer together by helping each other
                  </p>
                </div>
                <div className='imgWire Image3'>
                  <img src={image3} />
                  {/* <div className="wireframeImage" style={{ backgroundImage: `url('${image3}')` }}></div> */}
                </div>
              </div>
              <div className='stripe'>
                <div className='innerStripe'>
                  <img className='quoteS' src={quoteS} />
                  <h3>
                    You will get all you want in life, if you help enough other{" "}
                    <br className='hideMob' />
                    people get <br className='hidePc' />
                    what they want.
                  </h3>
                  <div className='byName'>
                    <p>Zig Ziglar</p>
                  </div>
                  <img className='quote' src={quote} />
                </div>
              </div>
              <div className='wireframeText '>
                <div className='imgWire'>
                  <img src={image4} />
                  {/* <div className="wireframeImage" style={{ backgroundImage: `url('${image4}')` }}></div> */}
                </div>
                <div className='textWire'>
                  <h2>Meet Like-Minded Achievers</h2>
                  <p>
                    Network with heart-centered go-getters <br />
                    Bring out the best in one another <br />
                    Mastermind with personal development enthusiasts
                  </p>
                </div>
              </div>
              <div className='stripe'>
                <div className='innerStripe'>
                  <img className='quoteS' src={quoteS} />
                  <h3>
                    Successful people are always looking for opportunities to
                    help others. <br className='hideMob' />
                    Unsuccessful people are always asking ‘What’s in it for me?’
                  </h3>
                  <div className='byName'>
                    <p>brian tracy</p>
                  </div>
                  <img className='quote' src={quote} />
                </div>
              </div>
            </div>

            <div className='sectionFifth hideMob'>
              <div className='innerSection'>
                <div className='slider'>
                  <div className='innerSlider'>
                    <div
                      id='slide1'
                      className='slide leftImage active'
                      onClick={(ev) =>
                        this.changeSlide(
                          ev.currentTarget,
                          "slide1",
                          "slide1Nav"
                        )
                      }
                    >
                      <img src={slider1} />
                      <div className='lottieDiv'>
                        <Lottie options={this.state.defaultOptionsGoal} />
                      </div>
                    </div>
                    <div
                      id='slide2'
                      className='slide rightImage'
                      onClick={(ev) =>
                        this.changeSlide(
                          ev.currentTarget,
                          "slide2",
                          "slide2Nav"
                        )
                      }
                    >
                      <img src={slider2} />
                    </div>
                  </div>
                  <div className='sliderNavs'>
                    <div
                      id='slide1Nav'
                      className='navBtn navActive'
                      onClick={(ev) =>
                        this.changeSlide(
                          ev.currentTarget,
                          "slide1",
                          "slide1Nav"
                        )
                      }
                    >
                      <svg
                        width='12'
                        height='11'
                        viewBox='0 0 12 11'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <circle cx='5.96087' cy='5.27435' r='5.1474' />
                      </svg>
                    </div>
                    <div
                      id='slide2Nav'
                      className='navBtn'
                      onClick={(ev) =>
                        this.changeSlide(
                          ev.currentTarget,
                          "slide2",
                          "slide2Nav"
                        )
                      }
                    >
                      <svg
                        width='12'
                        height='11'
                        viewBox='0 0 12 11'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <circle cx='5.96087' cy='5.27435' r='5.1474' />
                      </svg>
                    </div>
                  </div>
                </div>

                {/* <div className="">
                                <Slider  {...settingsIamgesMob}>
                                <div  className="slide" >
                                        <img src={slider1} />
                                    </div>
                                    <div className="slide" >
                                        <img src={slider2} />
                                    </div>
                                </Slider>
                            </div> */}
              </div>
            </div>

            <div className='sectionFifth hidePc'>
              <div className='innerSection'>
                <div className='slider'>
                  {/* <div className="innerSlider"> */}
                  <Slider {...sectionFifthSlider}>
                    <div id='slide1' className='slide leftImage active'>
                      <img src={slider1} />
                      <div className='lottieDiv'>
                        <Lottie options={this.state.defaultOptionsGoal} />
                      </div>
                    </div>
                    <div id='slide2' className='slide rightImage active'>
                      <img src={slider2} />
                    </div>
                  </Slider>
                  {/* </div> */}
                </div>
              </div>
            </div>
            <div className='stripe'>
              <div className='innerStripe'>
                <img className='quoteS' src={quoteS} />
                <h3>
                  Networking that matters is helping <br className='hideMob' />
                  people achieve their goals.
                </h3>
                <div className='byName'>
                  <p>Seth Godin</p>
                </div>
                <img className='quote' src={quote} />
              </div>
            </div>
          </div>
          <div className='footerV5'>
            <div className='innerFooter backgroundWhite'>
              <div className='downloadFooterBtn splashScreenV7Footer'>
                {/* {!this.state.tapBool ? (
                  <h3>
                    Is GoalMogul right for you?
                    <br />
                    <span
                      className='tapToFind'
                      onClick={() => this.setState({ tapBool: true })}
                    >
                      Tap here to find out!
                    </span>
                  </h3>
                ) : null} */}
                {/* {this.state.tapBool ? (
                  <> */}
                <h4>
                  Is GoalMogul right for you?
                  <br />
                  <span className='tapToFind'>
                    {" "}
                    GoalMogul is right for you if you want to
                  </span>
                </h4>
                <ul>
                  <li>Keep a pulse on what friends want in life</li>
                  <li>
                    Know who needs help, what they need help with, and when
                  </li>
                  <li>Have an easy, time-saving way to help</li>
                  <li>Have more productive and genuine friendships</li>
                  <li>Feel connected by supporting each other</li>
                  <li>Avoid losing touch as time goes on</li>
                  <li>Meet like-minded personal development enthusiasts </li>
                  <li>Never forget about old goals you’ve set</li>
                  <li>
                    Join the heart-centered H.O.P.E movement (Help Other People
                    Everyday){" "}
                  </li>
                </ul>
                {/* </>
                ) : null} */}
                {/* <h2><br/>Don't think. Don't wonder. Just try it. <br /> Download Now. </h2>
                                <div className="AppDownloadDiv">
                                    <a>

                                        <img className="buttonImage" src={playstore} />
                                    </a>
                                    <a>

                                        <img className="buttonImage" src={appstore} />
                                    </a>

                                </div> */}
              </div>
              <div className='tigerImgDiv'>
                <img src={tigerImage} />
              </div>
            </div>
            {/* <div className='footerLinksMain'>
                            <div className="footerLinks">
                                <div className="socialLinks">
                                    <div className="socialButton"><svg viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.24103 2.36578H7.44512V0.268657C7.23739 0.24008 6.52296 0.175781 5.69093 0.175781C3.95487 0.175781 2.76562 1.26776 2.76562 3.27475V5.12182H0.849854V7.46624H2.76562V13.3652H5.11444V7.46679H6.95271L7.24453 5.12237H5.11389V3.50721C5.11444 2.82961 5.29689 2.36578 6.24103 2.36578Z" fill="#42C0F5" />
                                    </svg>
                                    </div>
                                    <div className="socialButton"><svg viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.8504 2.14465C12.3812 2.35045 11.8813 2.48686 11.3601 2.55309C11.8963 2.23296 12.3055 1.7299 12.4979 1.12355C11.998 1.4216 11.446 1.63213 10.8578 1.74961C10.3831 1.24419 9.70661 0.931152 8.96858 0.931152C7.53666 0.931152 6.38388 2.0934 6.38388 3.51822C6.38388 3.72323 6.40122 3.92035 6.4438 4.10801C4.29357 4.00314 2.39092 2.97258 1.11276 1.40267C0.889618 1.78983 0.758727 2.23296 0.758727 2.71001C0.758727 3.60574 1.22 4.39976 1.90757 4.85945C1.49203 4.85157 1.08438 4.73093 0.739014 4.5409C0.739014 4.54879 0.739014 4.55904 0.739014 4.56929C0.739014 5.82615 1.63554 6.87013 2.81119 7.11062C2.60066 7.16818 2.37121 7.19578 2.13308 7.19578C1.9675 7.19578 1.80033 7.18631 1.64342 7.15162C1.97853 8.17588 2.92946 8.9289 4.06017 8.95334C3.18021 9.6417 2.0629 10.0564 0.853347 10.0564C0.64124 10.0564 0.437808 10.047 0.234375 10.021C1.38006 10.7598 2.73786 11.1816 4.2021 11.1816C8.96148 11.1816 11.5635 7.23914 11.5635 3.82179C11.5635 3.70746 11.5596 3.59707 11.5541 3.48747C12.0674 3.12318 12.4987 2.66822 12.8504 2.14465Z" fill="#42C0F5" />
                                    </svg>
                                    </div>
                                    <div className="socialButton"><svg viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path d="M12.0581 12.2178V12.2173H12.0611V8.01103C12.0611 5.95329 11.5959 4.36816 9.07 4.36816C7.85571 4.36816 7.04083 5.00279 6.70816 5.60443H6.67303V4.56027H4.27808V12.2173H6.77188V8.42583C6.77188 7.42754 6.97058 6.46223 8.26867 6.46223C9.54769 6.46223 9.56675 7.60149 9.56675 8.48986V12.2178H12.0581Z" fill="#42C0F5" />
                                            <path d="M0.217285 4.56055H2.7141V12.2176H0.217285V4.56055Z" fill="#42C0F5" />
                                            <path d="M1.46466 0.748535C0.666342 0.748535 0.0185547 1.36548 0.0185547 2.12578C0.0185547 2.88608 0.666342 3.51593 1.46466 3.51593C2.26298 3.51593 2.91077 2.88608 2.91077 2.12578C2.91027 1.36548 2.26248 0.748535 1.46466 0.748535V0.748535Z" fill="#42C0F5" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="12.0425" height="11.4691" fill="white" transform="translate(0.0185547 0.748535)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    </div>
                                    <div className="socialButton"><svg viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path d="M9.09374 0.924805H3.57083C1.77335 0.924805 0.311035 2.38712 0.311035 4.1846V9.70761C0.311035 11.505 1.77335 12.9673 3.57083 12.9673H9.09384C10.8912 12.9673 12.3535 11.505 12.3535 9.70761V4.1846C12.3535 2.38712 10.8912 0.924805 9.09374 0.924805V0.924805ZM6.33229 10.2388C4.51661 10.2388 3.03951 8.76173 3.03951 6.94606C3.03951 5.13038 4.51661 3.65328 6.33229 3.65328C8.14796 3.65328 9.62507 5.13038 9.62507 6.94606C9.62507 8.76173 8.14796 10.2388 6.33229 10.2388ZM9.70381 4.42982C9.16724 4.42982 8.73083 3.99341 8.73083 3.45684C8.73083 2.92028 9.16724 2.48377 9.70381 2.48377C10.2404 2.48377 10.6769 2.92028 10.6769 3.45684C10.6769 3.99341 10.2404 4.42982 9.70381 4.42982Z" fill="#42C0F5" />
                                            <path d="M6.3324 4.35938C4.9061 4.35938 3.74561 5.51978 3.74561 6.94617C3.74561 8.37247 4.9061 9.53297 6.3324 9.53297C7.75879 9.53297 8.9192 8.37247 8.9192 6.94617C8.9192 5.51978 7.75879 4.35938 6.3324 4.35938Z" fill="#42C0F5" />
                                            <path d="M9.70401 3.18994C9.55682 3.18994 9.43701 3.30975 9.43701 3.45694C9.43701 3.60412 9.55682 3.72393 9.70401 3.72393C9.85128 3.72393 9.97109 3.60422 9.97109 3.45694C9.97109 3.30966 9.85128 3.18994 9.70401 3.18994Z" fill="#42C0F5" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="12.0425" height="12.0425" fill="white" transform="translate(0.311035 0.924805)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    </div>
                                    <div className="socialButton"><svg viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path d="M13.9493 3.83157C13.8007 3.27881 13.3648 2.84307 12.8122 2.69423C11.8025 2.41797 7.76369 2.41797 7.76369 2.41797C7.76369 2.41797 3.72506 2.41797 2.71539 2.68376C2.17334 2.83244 1.72689 3.27888 1.57821 3.83157C1.3125 4.84116 1.3125 6.93494 1.3125 6.93494C1.3125 6.93494 1.3125 9.03927 1.57821 10.0383C1.72705 10.591 2.16271 11.0267 2.71546 11.1756C3.73569 11.4519 7.76385 11.4519 7.76385 11.4519C7.76385 11.4519 11.8025 11.4519 12.8122 11.1861C13.3649 11.0374 13.8007 10.6016 13.9495 10.0489C14.2151 9.03927 14.2151 6.94557 14.2151 6.94557C14.2151 6.94557 14.2258 4.84116 13.9493 3.83157ZM6.47783 8.86924V5.00064L9.83629 6.93494L6.47783 8.86924Z" fill="#42C0F5" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="13.7629" height="13.7629" fill="white" transform="translate(0.88916 0.175293)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    </div>
                                </div>
                                <div className="footerCopyWrite">
                                    Â© 2021 GoalMogul, Inc.
                            </div>
                                <div className="termsCoditionFooter">
                                    <div className="termsLink">Contact Support</div>
                                    <div className="termsLink">Terms of Service</div>
                                    <div className="termsLink">Privacy Policy</div>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    )
  }
}
