import React from "react"
import Brand from "../../assets/Logo.png"
import person_rounded from "../../assets/person_rounded.png"
import "../ReminderConfirmation.scss"

const SMSReminderConfirmation = () => {
  return (
    <div className='ExtraPages'>
      <div className='App'>
        <div className='app-header'>
          <div className='header-content'>
            <img src={Brand} alt='brand' />
          </div>
        </div>
        <div className='box-content reminder-content'>
          <p className='heading'>
            Reminders by SMS <br />
            Powered by GoalMogul
          </p>
          <p className='text'>Thanks!</p>
          <p className='text'> You will now receive SMS Reminders from:</p>
          <div>
            <img src={person_rounded} />
          </div>
          <p className='text'>
            <span className='blue-text'>
              Abdul Hannan
              <br />
              +1 347-374-1006
            </span>
          </p>
          <button className='reminder-button'>View Reminder</button>
          <p className='text'>
            You can unsubscribe from SMS Reminders anytime by texting "STOP" to
            347-374-1006
          </p>
        </div>
        <div className='app-footer'>
          <p>GoalMogul Inc. © 2022</p>
        </div>
      </div>
    </div>
  )
}
export default SMSReminderConfirmation
