import React from "react"
import Brand from "../../assets/Logo.png"
import person_rounded from "../../assets/person_rounded.png"
import "../ReminderConfirmation.scss"

const ReminderUnsubscribe = () => {
  return (
    <div className='ExtraPages ReminderUnsubscribe'>
      <div className='App'>
        <div className='app-header'>
          <div className='header-content'>
            <img src={Brand} alt='brand' />
          </div>
        </div>
        <div className='box-content reminder-content ReminderUnsubscribe-reminder-content'>
          <p className='heading ReminderUnsubscribeHeading'>
            You are now UNSUBSCRIBED from
            <br /> Abdul Hannan’s Email Reminders
          </p>

          <div>
            <img src={person_rounded} />
          </div>
          <p className='text'>We’re sorry to see you go...</p>
          <button className='reminder-button-unsub'>
            I want to continue receiving Email Reminders from %FullName%.
          </button>
        </div>
        <div className='app-footer'>
          <p>GoalMogul Inc. © 2022</p>
        </div>
      </div>
    </div>
  )
}
export default ReminderUnsubscribe
