import React, { useEffect, useState } from "react"
import navLogo from "../../assets/logoV5.png"
import "./whitenav.scss"
import playstore from "./../../assets/mobPlaystore.png"
import appStore from "./../../assets/mobIos.png"
import { withRouter } from "react-router"

function NavV5(props: any) {
  const [reminder, setReminder] = useState(false)
  useEffect(() => {
    const url = new URL(window.location.href)
    let key = url.searchParams.get("key")
    console.log(key, "keykey")
    if (key) {
      setReminder(true)
    }
  }, [])
  // const url = new URL(window.location.href)
  return (
    <div className='navV5'>
      <div className='innerNav'>
        <div className='logoDiv'>
          <img src={navLogo} onClick={() => props.history.push("/")} />
        </div>
      </div>
      {!reminder ? (
        <div className='mobDownload'>
          <div className='downloadBtn'>
            <img
              src={playstore}
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.goalmogul.goalmogulbeta",
                  "_blank"
                )
              }
            />
            <img
              src={appStore}
              onClick={() =>
                window.open(
                  "https://apps.apple.com/us/app/goalmogul/id1441503669",
                  "_blank"
                )
              }
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default withRouter(NavV5)
