import React, { useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { RouteComponentProps } from "react-router-dom"
import "./bronzechallenge.scss"
import handRightArrow from "./../../images/handIcon.svg"
import Star from "./../../images/Star.svg"
import Image from "./Image.png"
import Rectangle from "./Rectangle25.png"
// import image from './../../assets/newWireframe/Group 2355.png'
interface PropsInterface
  extends RouteComponentProps<{ code?: string; uniqueSlider?: any }> {}
//   extends RouteComponentProps<> {}

/**
 * This is the landing page. Show links to app store and list of features.
 */
// interface RouteParams<any>

export default class BronzeBadge extends React.Component<PropsInterface> {
  state = {
    tap: false,
  }
  constructor(props: any) {
    super(props)
  }
  componentDidMount() {
    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      window.location.href = "/download"
    }
  }

  render() {
    return (
      <div className='bronzeBadge'>
        <div id='mainDiv'>
          <div style={{ padding: "35px 0px" }}>
            <div className='HeadingLight'>
              <div>
                <p style={{ marginBottom: "4px" }}>Bronze Badge Challenge</p>
                <p className='p2'>
                  Prize: Free Lifetime Access to
                  <br /> #1 Book Summary Service ($499 Value)
                </p>
              </div>
            </div>
            <div className='imgDiv '>
              <img
                // style={{ height: "147px", width: "100px" }}
                // src='https://goalmogul-v1.s3-us-west-2.amazonaws.com/emailTemplates/5_pages/silverBadgeIMG.png'
                src={Image}
              />
            </div>
            {/* This should open when we hav the deeplink link badge screen in App */}
            {/* <div className='text fs-6  text-Left youAre1Bronze'>
              You can participate in this Challenge only if you have earned the Green Badge first. If you have not earned the Green Badge yet, {" "}
              <a href=''
                style={{ color: "black", textDecoration: "underline" }}
              >tap here to see how you can earn the Green Badge first!</a>
            </div>
            <br /> */}
            {/* <div className="text">
          CONTINUE READING ONLY IF YOU HAVE EARNED YOUR BRONZE BADGE.<br/> THIS CHALLENGE IS ONLY AVAILABLE FOR BRONZE BADGE HOLDERS.
          </div>
          <br/>
          <div className="Username">Hey {firstName},</div> */}
            {/* <div className='Username'>Hey John,</div> */}
            <div className='Username mb-3'>
              For a VERY LIMITED TIME only…
              <br />
              Get LIFETIME Access to{" "}
              <a target='_blank' href='http://readitfor.me/'>
                Readitfor.me:
              </a>
            </div>
            <div className='text text-center'>
              <b>
                The #1 Book Summary Service
                <br /> for Executives and Entrepreneurs
              </b>
            </div>
            <div className='text text-center freeText'>
              <b style={{ color: "#FB0303" }}>… for FREE!</b>
            </div>
            <div className='text text-center cliffText'>
              <i>“It’s like Cliff’s Notes for Business Books”</i>
            </div>
            <div style={{ margin: "5px auto" }}>
              <div className='text fs-6  text-Left youAre1Bronze'>
                You’re only 1 friend away from the triumph of winning the{" "}
                <b>Bronze Badge</b> and claiming this amazing reward!
              </div>
            </div>
            <div className='ShadowDiv'>
              <div className='text text-center margin-Bottom'>
                <b>Just complete these 4 simple steps:</b>
              </div>
              <div className='TextDivWithCheckBox'>
                <div className='stepCount'>1)</div>
                <div className='text fs-6 stpesText'>
                  Add a photo to your profile
                </div>
              </div>
              <div className='TextDivWithCheckBox'>
                <div className='stepCount'>2)</div>
                <div className='text fs-6 stpesText'>
                  Fill in your profile entirely
                </div>
              </div>
              <div className='TextDivWithCheckBox'>
                <div className='stepCount'>3)</div>
                <div className='text fs-6 stpesText'>Set 1 goal</div>
              </div>
              <div className='TextDivWithCheckBox'>
                <div className='stepCount'>4)</div>
                <div className='text fs-6 stpesText'>
                  Invite 1 friend who joins you on GoalMogul using your Invite
                  Code
                </div>
              </div>
            </div>
            <div className='text text-center'>
              <b style={{ color: "#FB0303" }}>🔥 REWARD DETAILS:</b>
            </div>
            {/* <div className='imgDiv Image'>
              <img
                style={{ height: "100%", width: "100%" }}
                src={Rectangle}
              />
            </div> */}
            <div className='ShadowDiv reDetail'>
              <div className='text fs-6 text-Left margin-Bottom'>
                Get access to written and audio summaries of 350+ books so you
                can:
              </div>
              <div className='TextDivWithCheckBox'>
                <div className='stepCount'>
                  <img src={handRightArrow} />
                </div>
                <div className='text fs-6'>
                  Quickly understand what the world’s top experts have to teach
                  about{" "}
                  <b style={{ color: "#000" }}>
                    leadership, business, wealth, fitness & more
                  </b>
                </div>
              </div>
              <div className='TextDivWithCheckBox'>
                <div className='stepCount'>
                  <img src={handRightArrow} />
                </div>
                <div className='text fs-6'>
                  Stay on top of the latest business and self improvement
                  thought leadership
                </div>
              </div>
              <div className='TextDivWithCheckBox'>
                <div className='stepCount'>
                  <img src={handRightArrow} />
                </div>
                <div className='text fs-6'>
                  Decide which books you want to invest 5-10 hours of your time
                  into
                </div>
              </div>
            </div>
            <div
              className='text fs-6'
              style={{ width: "100%", marginBottom: "32px", marginTop: "32px" }}
            >
              Get crystal clear, applicable & concise wisdom from billionaires,
              CEOs, world-class leaders and athletes without spending more than
              <b style={{ color: "#000" }}> 15 minutes!</b> You’ll have lifetime
              access to summaries of popular titles such as these:
            </div>
            <div className='ShadowDiv applicaList'>
              <div className='text fs-6 mb-0 white-space'>
                📖 Life Force - Tony Robbins
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 Business Stripped Bare - Richard Branson
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 Becoming - Michelle Obama
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 Twelve and a Half - Gary Vaynerchuk
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 Principles - Ray Dalio
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 Atomic Habits - James Clear
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 You are a Badass - Jen Sincero
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 The Subtle Art of Not Giving a &%$# - Mark Manson
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 Crossing the Chasm - Geoffrey Moore
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 Hooked - Nir Eyal
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 This is Marketing - Seth Godin{" "}
              </div>
              <div className='text fs-6 mb-0 white-space'>
                📖 Flip the Funnel - Joseph Jaffe
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 High-Profit Prospecting - Mark Hunter
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 Neuromarketing - Christopher Morin
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 Can't Hurt Me - David Goggins
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 The 8th Habit - Steven Covey
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 Good to Great - Jim Collins
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 How to Win Friends & Influence People - Dale Carnegie
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 Unlimited Power - Tony Robbins
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 Delivering Happiness - Tony Hsieh
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 Crush It - Gary Vaynerchuk
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 The New Social Learning - Marcia Conner
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 Tribal Leadership - Dave Logan
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 Winning - Jack Welch
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 Winning Body Language - Mark Bowden
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 The Thank You Economy - Gary Vaynerchuk
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 4 Hour Body - Tim Ferris
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 The Lean Startup - Eric Ries
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 Start With Why - Simon Sinek
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 E-Myth Revisited - Michael Gerber
              </div>
              <div className='text fs-6 mb-0 white-space'>
                {" "}
                📖 The 80/20 Principle - Richard Koch{" "}
              </div>
              <div className='text fs-6 mb-0 white-space'>
                📖 First, Break All the Rules - Marcus Buckingham{" "}
              </div>
              <div className='text fs-6 mb-0 white-space'>
                📖 Contagious - Jonah Bergder{" "}
              </div>
              <div className='text fs-6 mb-0 white-space'>
                📖 Building a Story Brand - Donald Miller{" "}
              </div>
              <div className='text fs-6 mb-0 white-space'>
                📖 The Tipping Point - Malcolm Gladwell{" "}
              </div>
              <div className='text fs-6 mb-0 white-space'>
                📖 Rising Strong - Brene Brown{" "}
              </div>
              <div className='text fs-6 mb-0 white-space'>
                📖 The Power of Habit - Charles Duhigg{" "}
              </div>
              <div className='text fs-6 mb-0 white-space'>
                📖 The Ultimate Sales Letter - Dan Kennedy{" "}
              </div>
              <div className='text fs-6 mb-0 white-space'>
                📖 The Greatest Salesman in the World - Og Mandino{" "}
              </div>
              <div className='text fs-6 mb-0 white-space'>
                📖 Purple Cow - Seth Godin{" "}
              </div>
              <div className='text fs-6 mb-0 white-space'>
                📖 Tribes - Seth Godin{" "}
              </div>
              <div className='text fs-6 mb-0 white-space'>
                📖 Influence - Robert Cialdini{" "}
              </div>
              <div className='text fs-6 mb-0 white-space'>
                📖 Pitch Anything - Oren Klaff{" "}
              </div>
              <div className='text fs-6 mb-0 white-space'>
                📖 Leaders Eat Last - Simon Sinek{" "}
              </div>
              <div className='text fs-6 mb-0 white-space'>
                📖 Zero to One - Peter Thiel{" "}
              </div>
              <div className='text fs-6 mb-0 white-space'>
                📖 Bold - Peter Diamandis{" "}
              </div>
              <div className='text fs-6 mb-0 white-space'>
                📖 The Psychology of Selling - Brian Tracy{" "}
              </div>
              <div className='text fs-6 mb-0 white-space'>
                📖 John Maxwell - The 21 Irrefutable Laws of Leadership{" "}
              </div>
              <div className='text fs-6 mb-0 white-space'>
                📖 Think and Grow Rich - Napoleon Hill
              </div>
            </div>
            <div className='text text-center'>
              <p style={{ color: "#FB0303" }}>
                And 300+ more! <br />
                (New book summaries added every week!)
              </p>
            </div>
            <div
              className='text text-center fs-6'
              style={{ width: "98%", textAlign: "center", marginTop: "13px" }}
            >
              After you get your Bronze Badge, you get them{" "}
              <b style={{ color: "black" }}>ALL!</b>
            </div>
            <div className='text fs-6 text-center'>
              Don’t miss out!
              <br /> Get your
              <b> Bronze Badge </b>
              <b style={{ color: "#000" }}>now!</b>
            </div>
            {/* <div className='text text-center'>
              <b style={{ color: "#FB0303" }}>
                *** IMPORTANT PRIZE DISCLAIMER! ***
              </b>
            </div> */}
            <div className='ShadowDiv'>
              <div className='text text-center'>
                <b>
                  To win the prizes, you must achieve your Bronze
                  <br /> Badge. You’ll earn the Bronze Badge after
                  <br /> you’ve completed these 4 steps:
                </b>
              </div>
              <div className='TextDivWithCheckBox'>
                <div className='stepCount'>1)</div>
                <div className='text fs-6'>
                  Fill in each field of your GoalMogul profile
                </div>
              </div>
              <div className='TextDivWithCheckBox'>
                <div className='stepCount'>2)</div>
                <div className='text fs-6'>Add a profile photo</div>
              </div>
              <div className='TextDivWithCheckBox'>
                <div className='stepCount'>3)</div>
                <div className='text fs-6'>Set 1 goal</div>
              </div>
              <div className='TextDivWithCheckBox'>
                <div className='stepCount'>4)</div>
                <div className='text fs-6'>
                  You have 1 or more friends sign up for GoalMogul using your
                  Invite Code
                </div>
              </div>
            </div>
            <div className='text text-center'>
              <b style={{ color: "#FB0303" }}>
                *** IMPORTANT PRIZE DISCLAIMER! ***
              </b>
            </div>
            {/* <div className='text-center' onClick={() =>
              this.setState({
                tap: true,
              })
            }>
              <b style={{ color: "#42C0F5", fontSize: "18px" }}>
                Tap here to get started!
              </b>
            </div> */}

            <div
              style={{
                margin: "5px auto",
                marginBottom: "149px",
                padding: "0px 14px",
              }}
            >
              {/* <div
                className='text fs-6  text-Left font_weight'
                style={{ color: "#42C0F5", textDecoration: "underline" }}
                onClick={() =>
                  this.setState({
                    tap: true,
                  })
                }
              >
                To read more about other{" "}
                <strong
                // style={{ textDecoration: "underline" }}
                >
                  GoalMogul Badges and Prizes,
                </strong>{" "}
                <a
                // style={{ color: "#42C0F5" }}
                >
                  tap here.
                </a>
              </div> */}
              {/* {this.state.tap ? (
                <> */}
              <div
                className='text'
                style={{ lineHeight: "24px", color: "black" }}
              >
                Upon winning your Bronze Badge, you will receive a notification
                via <strong>EMAIL</strong> , along with links to download your
                prizes.
              </div>
              <div style={{ margin: "20px auto" }}>
                <div className='footerText'>Please contact</div>
                <div className='footerText'>
                  <a
                    href='mailto:support@goalmogul.com'
                    style={{ color: "#42c0f5" }}
                  >
                    {" "}
                    support@goalmogul.com
                  </a>
                </div>
                <div className='footerText'>if you have any questions.</div>
              </div>
              {/* </>
              ) : null} */}
              {/* <div className='TextDivWithCheckBox'>
                  <div>
                    <img src={Star} />
                  </div>
                  <div className='text'>
                    <b>Full Name</b>
                  </div>
                </div>
                <div className='TextDivWithCheckBox'>
                  <div>
                    <img src={Star} />
                  </div>
                  <div className='text'>
                    <b>PayPal ID</b>
                  </div>
                </div> */}
              {/* <div style={{ margin: "20px auto", marginBottom: "20%" }}>
                <div className='footerText'>Please contact</div>
                <div className='footerText'>
                  <b style={{ color: "#42c0f5" }}> support@goalmogul.com</b>
                </div>
                <div className='footerText'>if you have any questions.</div>
              </div> */}
              {/* <div className="text" style={{textAlign: "center",}}>
            Didn't mean to unsubscribe? Click here to resubscribe.
          </div> */}
            </div>
            <div className='buttonsMainDiv'>
              <button
                style={{ margin: 0, marginTop: 15 }}
                onClick={() =>
                  (window.location.href =
                    "https://goalmogul.page.link/invitefriend")
                }
                className='buttonBlue'
              >
                Invite Friends
              </button>
              <button
                style={{ margin: 0, marginTop: 15, marginBottom: 10 }}
                onClick={() =>
                  (window.location.href =
                    "https://goalmogul.page.link/creategoal")
                }
                className='buttonTransparent'
              >
                Create Goal
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
