import React, { useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { RouteComponentProps } from "react-router-dom"
import "./GoldBadge.scss"
import handRightArrow from "./../../images/handIcon.svg"
import Star from "./../../images/Star.svg"
// import image from './../../assets/newWireframe/Group 2355.png'
interface PropsInterface
  extends RouteComponentProps<{ code?: string; uniqueSlider?: any }> {}
//   extends RouteComponentProps<> {}

/**
 * This is the landing page. Show links to app store and list of features.
 */
// interface RouteParams<any>

export default class GoldBadge extends React.Component<PropsInterface> {
  state = {
    tap: false,
  }
  constructor(props: any) {
    super(props)
  }
  componentDidMount() {
    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      window.location.href = "/download"
    }
  }

  render() {
    return (
      <div className='GoldBadge'>
        <div id='mainDiv'>
          <div style={{ margin: "35px 15px" }}>
            <div className='HeadingLight'>
              <div>
                <p>30 Day Gold Badge Challenge</p>
                <p className='p2'>Grand Prize: $1000 Cash</p>
              </div>
            </div>
            <div className='imgDiv '>
              <img
                style={{ height: "100%", width: "100%" }}
                src='https://goalmogul-v1.s3-us-west-2.amazonaws.com/emailTemplates/5_pages/GoldBadgeIMG.png'
              />
            </div>
            <div className='text' style={{ color: "rgb(251, 3, 3)" }}>
              <b>
                THE GOLD BADGE CHALLENGE HAS *NOT* YET BEGUN. WE WILL NOTIFY YOU
                WHEN THIS CHALLENGE BEGINS. IN THE MEAN TIME, IF YOU’D LIKE A
                SNEAK-PEEK AT WHAT THIS CHALLENGE IS ABOUT, FEEL FREE TO KEEP ON
                READING…
              </b>
            </div>
            <br />
            {/* <div className="text">
          CONTINUE READING ONLY IF YOU HAVE EARNED YOUR SILVER BADGE.<br/> THIS CHALLENGE IS ONLY AVAILABLE FOR SILVER BADGE HOLDERS.
          </div>
          <br/>
          <div className="Username">Hey {firstName},</div> */}
            <div className='Username'>
              Leaders are people who can bring people together, inspire them and
              help them achieve their goals.
            </div>
            <div style={{ margin: "5px auto" }}>
              <div className='text'>
                <p style={{ margin: "6px auto" }}>
                  Forbes says that ‘team building’ and ‘making lasting
                  relationships’ are two of the most important traits in
                  leaders.
                </p>
                <p style={{ margin: "6px auto" }}>Think for a moment...</p>
                <p style={{ margin: "6px auto" }}>
                  How many times have you wished someone would support you and
                  lift you up?
                </p>
                <p style={{ margin: "6px auto" }}>
                  It’s time you become that support for others...
                </p>
                <p style={{ margin: "6px auto" }}>
                  <i>WE RISE BY LIFTING OTHERS.</i>
                </p>
                <p style={{ margin: "6px auto" }}>
                  The Gold Badge tests your ability to lead others to reclaim
                  their dreams.
                </p>
                <p style={{ margin: "6px auto" }}>
                  If you believe you are destined to lead your friends and be an
                  inspiration to others, THEN get your Gold Badge now
                </p>
                <p style={{ margin: "6px auto" }}>
                  To earn your Gold Badge, you need to have invited a total of
                  10 Bronze Badge users.
                </p>
                <p style={{ margin: "6px auto" }}>
                  <i>
                    You already have lots of Friends, so you’re not far off!
                  </i>
                </p>
                <span
                  style={{
                    color: "#FB0303",
                    margin: "6px auto",
                    width: "100%",
                    textAlign: "center",
                    display: "inline-block",
                  }}
                >
                  To motivate the overachiever in you, we’re offering the chance
                  to win $1,000!{" "}
                </span>
                {/* <br />
              <br /> */}
                <div
                  className='ShadowDiv'
                  style={{ padding: "13px 10px", textAlign: "center" }}
                >
                  <b>HOW TO WIN WIN THE 30-DAY GOLD BADGE CHALLENGE:</b>
                  <br />
                  <br />
                  It’s time you become that support for others...
                  <br />
                  <br />
                  Be the ONE to{" "}
                  <span style={{ color: "#FB0303" }}>
                    INVITE THE MOST
                    <br /> BRONZE BADGE USERS in 30 days
                  </span>{" "}
                  and win!
                  <br />
                  <br />
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <div>🔥 🔥 🔥 </div>
                      <div
                        className='text'
                        style={{ width: "60%", textAlign: "center" }}
                      >
                        <b style={{ color: "#FB0303", fontSize: "16px" }}>
                          GRAND PRIZE: $1000 CASH
                        </b>
                        <br />
                      </div>
                      <div>🔥 🔥 🔥 </div>
                    </div>
                    <div
                      className='text midsmallText'
                      style={{ margin: "-1px auto", textAlign: "center" }}
                    >
                      {" "}
                      (paid via Paypal, see below for details***)
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='text' style={{ margin: "20px auto" }}>
              <p style={{ margin: "8px auto" }}>
                Never doubt -- because anything is possible!
              </p>
              <p style={{ margin: "8px auto" }}>
                <b>
                  What if you're the one who will laugh your way to the bank and
                  no others?
                </b>
              </p>
              <p style={{ margin: "8px auto" }}>
                <b>
                  YOU ONLY HAVE 30 DAYS TO COMPLETE THIS CHALLENGE AFTER WE
                  ANNOUNCE THE BEGIN DATE!
                </b>
              </p>
              <p style={{ margin: "8px auto" }}>
                <b>Prove yourself to be a leader -- and get paid for it!! 💵</b>
              </p>
            </div>
            {/* <div style={{ textAlign: "center" }}>
            <button className="buttonBlue2">Invite More Friends</button>
          </div> */}
            <div className='buttonsMainDiv'>
              <button
                onClick={() =>
                  (window.location.href =
                    "https://goalmogul.page.link/invitefriend")
                }
                className='buttonBlue'
              >
                Invite My Friends to GoalMogul!
              </button>
            </div>
            <div
              className='text'
              style={
                this.state.tap
                  ? { textAlign: "center" }
                  : { textAlign: "center", marginBottom: "95px" }
              }
              onClick={() =>
                this.setState({
                  tap: true,
                })
              }
            >
              <b
                style={{
                  color: "#FB0303",
                  fontSize: "12px",
                }}
              >
                *** Tap here to read IMPORTANT Grand Prize Disclaimer! ***
              </b>
            </div>
            {this.state.tap ? (
              <>
                <div
                  className='text'
                  style={{ lineHeight: "24px", color: "#000000" }}
                >
                  To win the $1,000 prize, you must be the user with the most
                  invited Friends who achieve Bronze Badge level or higher badge
                  level <b>within 30 days of the Challenge begin date.</b>
                  {/* <br /> <span className="hypLink" style={{ color: "#42C0F5" }}>To read more about GoalMogul Badge
              Levels, tap here.
            </span> */}
                  <br />
                  <br />
                  YOU MUST HAVE A PAYPAL ACCOUNT in order for us to send you the
                  $1,000 cash prize. At the end of this Challenge, we will
                  announce the winner of the $1,000 Grand Prize.
                  <br />
                  <br />
                  If you are the $1,000 Grand Prize winner, coach@goalmogul.com
                  will email you to request the following information for
                  verification purposes:
                  <br />
                  <br />
                </div>
                <div className='TextDivWithCheckBox'>
                  <div>
                    <img src={Star} />
                  </div>
                  <div className='text'>
                    <b>Full Name</b>
                  </div>
                </div>
                <div className='TextDivWithCheckBox'>
                  <div>
                    <img src={Star} />
                  </div>
                  <div className='text'>
                    <b>PayPal ID</b>
                  </div>
                </div>
                <div style={{ margin: "30px auto 34%" }}>
                  <div className='footerText'>Please contact</div>
                  <div className='footerText'>
                    <a
                      href='mailto:support@goalmogul.com'
                      style={{ color: "#42c0f5" }}
                    >
                      {" "}
                      support@goalmogul.com
                    </a>
                  </div>
                  <div className='footerText'>if you have any questions.</div>
                </div>
                {/* <div className="text" style={{textAlign: "center",}}>
            Didn't mean to unsubscribe? Click here to resubscribe.
          </div> */}
              </>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}
