import React, { useEffect } from "react"

import axios from "axios"
import Brand from "../assets/Logo.png"
import GroupLogo from "../assets/Group 2542.png"
const Resubscribe = () => {
  let id = null

  useEffect(() => {
    id = localStorage.getItem("userid")
    const responce = async () => {
      const res = await axios.get(
        "https://goalmogul-api-dev.herokuapp.com/sub/resubscribe?id=" + id
      )
      console.log("responce===>", res)
      alert("You have successfully subscribed to the mailing list.")
    }
    responce()
  }, [])

  return (
    <div className='ExtraPages'>
      <div className='App'>
        <div className='app-header'>
          <div className='header-content'>
            <img src={Brand} alt='brand' />
          </div>
        </div>
        <div className='box-content WeeklyUpdates-content'>
          <div>
            <img src={GroupLogo} alt='Capture' />
            {/* <img src={BrandShort} className="Img" alt="brand" /> */}
            <h5 className='box-h5'>Welcome back!</h5>
            <p>
              You have opted back in to receive motivational emails from{" "}
              <a
                href='mailto:Coach@goalmogul.com'
                style={{ textDecoration: "none", fontWeight: 500 }}
                target={"_blank"}
              >
                Coach@goalmogul.com
              </a>
            </p>
          </div>
          {/* <h6>Manage other email preferences</h6> */}
        </div>

        <div className='app-footer'>
          <p>GoalMogul Inc. © 2022</p>
        </div>
      </div>
    </div>

    // <div style={{ marginTop: 50, height: 600 }}>
    //   <h1 style={{ textAlign: "center" }}>
    //     You have successfully subscribed to the mailing list
    //   </h1>
    // </div>
  )
}
export default Resubscribe
