import React, { useState } from "react"
import "./DeleteAccount.css" // Import your CSS file for styling

const DeleteAccount = () => {
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Here, you would typically send a request to your backend to send the magic link to the provided email
    // For example, using fetch or axios

    // For demo purposes, just setting a message
    setMessage(`Magic link sent to ${email}`)
  }

  return (
    <div className='delete-account-container'>
      <h1>Delete Account</h1>
      <p className='instructions'>
        Please enter your registered email in GoalMogul:
      </p>
      <form onSubmit={handleSubmit}>
        <label className='form-label'>
          Email:
          <input
            type='email'
            value={email}
            onChange={handleEmailChange}
            className='input-field'
          />
        </label>
        <button type='submit' className='submit-button'>
          Submit
        </button>
      </form>
      {message && <p className='success-message'>{message}</p>}
    </div>
  )
}

export default DeleteAccount
