import React, { useRef, useEffect, useState } from "react"

interface VideoThumbnailProps {
  videoFileName: string
  state: any // Replace 'any' with the actual type of your state
  setState: React.Dispatch<React.SetStateAction<any>> // Replace 'any' with the actual type of your state
}

const VideoThumbnail: React.FC<VideoThumbnailProps> = ({
  videoFileName,
  state,
  setState,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [thumbnailDataURL, setThumbnailDataURL] = useState<string>("")

  useEffect(() => {
    captureThumbnail()
  }, [videoFileName])

  const captureThumbnail = () => {
    const video = videoRef.current

    if (video) {
      // Create an off-screen canvas
      const offScreenCanvas = document.createElement("canvas")
      const ctx = offScreenCanvas.getContext("2d")

      // Set the canvas dimensions to match the video
      offScreenCanvas.width = video.videoWidth
      offScreenCanvas.height = video.videoHeight

      // Draw the current frame of the video onto the off-screen canvas
      ctx?.drawImage(video, 0, 0, offScreenCanvas.width, offScreenCanvas.height)

      offScreenCanvas.toBlob(function (blob: any) {
        console.log(blob)
        if (blob) {
          const lurl = URL.createObjectURL(blob)
          console.log(lurl) // this line should be here
        }
      }, "image/jpeg")
      offScreenCanvas.toBlob(
        (blob: any) => {
          // Use FileReader to read the Blob as a data URL
          console.log(blob)
          //ts-
          if (blob) {
            //ts-ignore
            const reader = new FileReader()
            reader.onloadend = () => {
              // Set the base64 URL to state
              console.log(reader.result)
              // setThumbnailDataURL(reader.result)
            }
            console.log(reader.readAsDataURL(blob))
          }
        },
        "image/jpeg", // Specify the format of the Blob
        0.8 // Quality setting for JPEG, 0.8 is a good balance between size and quality
      )
    }
  }

  return (
    <div className='videoThumb'>
      <video
        ref={videoRef}
        controls={false}
        muted
        onLoadedMetadata={captureThumbnail}
      >
        <source src={`${videoFileName}`} type='video/mp4' />
      </video>

      {/* Display the base64 URL */}
      {thumbnailDataURL && (
        <img
          src={thumbnailDataURL}
          alt='Thumbnail'
          style={{ width: "100%", height: "auto", cursor: "pointer" }}
          onClick={() => {
            console.log(setState, state, "chalgaya")
            setState({
              showModalVideo: true,
            })
          }}
        />
      )}
    </div>
  )
}

export default VideoThumbnail
