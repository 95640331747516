import React from "react"
import "./Footer.scss"
import playstore from "./../../assets/v6 wireframes/playStore.png"
import appstore from "./../../assets/v6 wireframes/appStore.png"

/**
 * Footer component shared by all pages
 */
export default function Footer() {
  return (
    <footer>
      <div className='footerDownloadNow'>
        <h2>
          Don't think. Don't wonder. Just try it. <br className='hidePc' />{" "}
          Download Now.{" "}
        </h2>
        <div className='AppDownloadDiv'>
          <a
            target='_blank'
            href='https://play.google.com/store/apps/details?id=com.goalmogul.goalmogulbeta'
          >
            <img className='buttonImage' src={playstore} />
          </a>
          <a
            target='_blank'
            href='https://apps.apple.com/us/app/goalmogul/id1441503669'
          >
            <img className='buttonImage' src={appstore} />
          </a>
        </div>
      </div>
      <div className='footerLinks'>
        <div className='grow p33 socailIcons'>
          <div>
            <svg
              onClick={() =>
                window.open("https://www.facebook.com/goalmogul", "_blank")
              }
              width='36'
              height='36'
              viewBox='0 0 36 36'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='17.9761' cy='18.002' r='17.2676' fill='#DEF7FF' />
              <g clipPath='url(#clip0)'>
                <path
                  d='M20.2413 13.4205H21.4454V11.3233C21.2376 11.2948 20.5232 11.2305 19.6912 11.2305C17.9551 11.2305 16.7659 12.3224 16.7659 14.3294V16.1765H14.8501V18.5209H16.7659V24.4199H19.1147V18.5215H20.953L21.2448 16.1771H19.1141V14.5619C19.1147 13.8843 19.2971 13.4205 20.2413 13.4205Z'
                  fill='#42C0F5'
                />
              </g>
              <defs>
                <clipPath id='clip0'>
                  <rect
                    width='13.1894'
                    height='13.1894'
                    fill='white'
                    transform='translate(11.4497 11.2305)'
                  />
                </clipPath>
              </defs>
            </svg>

            <svg
              onClick={() =>
                window.open("https://twitter.com/GoalMogul", "_blank")
              }
              width='35'
              height='36'
              viewBox='0 0 35 36'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='17.4283' cy='18.002' r='17.2676' fill='#DEF7FF' />
              <g clipPath='url(#clip0)'>
                <path
                  d='M23.8504 14.2018C23.3812 14.4076 22.8813 14.544 22.3601 14.6102C22.8963 14.2901 23.3055 13.787 23.4979 13.1807C22.998 13.4787 22.446 13.6893 21.8578 13.8067C21.3831 13.3013 20.7066 12.9883 19.9686 12.9883C18.5367 12.9883 17.3839 14.1505 17.3839 15.5753C17.3839 15.7804 17.4012 15.9775 17.4438 16.1651C15.2936 16.0603 13.3909 15.0297 12.1128 13.4598C11.8896 13.847 11.7587 14.2901 11.7587 14.7671C11.7587 15.6629 12.22 16.4569 12.9076 16.9166C12.492 16.9087 12.0844 16.7881 11.739 16.598C11.739 16.6059 11.739 16.6162 11.739 16.6264C11.739 17.8833 12.6355 18.9273 13.8112 19.1677C13.6007 19.2253 13.3712 19.2529 13.1331 19.2529C12.9675 19.2529 12.8003 19.2434 12.6434 19.2087C12.9785 20.233 13.9295 20.986 15.0602 21.0105C14.1802 21.6988 13.0629 22.1136 11.8533 22.1136C11.6412 22.1136 11.4378 22.1041 11.2344 22.0781C12.3801 22.8169 13.7379 23.2388 15.2021 23.2388C19.9615 23.2388 22.5635 19.2963 22.5635 15.8789C22.5635 15.7646 22.5596 15.6542 22.5541 15.5446C23.0674 15.1803 23.4987 14.7253 23.8504 14.2018Z'
                  fill='#42C0F5'
                />
              </g>
              <defs>
                <clipPath id='clip0'>
                  <rect
                    width='12.616'
                    height='12.616'
                    fill='white'
                    transform='translate(11.2344 11.8047)'
                  />
                </clipPath>
              </defs>
            </svg>

            <svg
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/goalmogul/",
                  "_blank"
                )
              }
              width='36'
              height='36'
              viewBox='0 0 36 36'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='17.88' cy='18.002' r='17.2676' fill='#DEF7FF' />
              <g clipPath='url(#clip0)'>
                <path
                  d='M24.0578 23.2735V23.273H24.0608V19.0667C24.0608 17.0089 23.5957 15.4238 21.0698 15.4238C19.8555 15.4238 19.0406 16.0585 18.7079 16.6601H18.6728V15.6159H16.2778V23.273H18.7716V19.4815C18.7716 18.4832 18.9703 17.5179 20.2684 17.5179C21.5474 17.5179 21.5665 18.6572 21.5665 19.5455V23.2735H24.0578Z'
                  fill='#42C0F5'
                />
                <path
                  d='M12.2168 15.6172H14.7136V23.2742H12.2168V15.6172Z'
                  fill='#42C0F5'
                />
                <path
                  d='M13.4642 11.8047C12.6659 11.8047 12.0181 12.4216 12.0181 13.1819C12.0181 13.9422 12.6659 14.5721 13.4642 14.5721C14.2625 14.5721 14.9103 13.9422 14.9103 13.1819C14.9098 12.4216 14.262 11.8047 13.4642 11.8047V11.8047Z'
                  fill='#42C0F5'
                />
              </g>
              <defs>
                <clipPath id='clip0'>
                  <rect
                    width='12.0425'
                    height='11.4691'
                    fill='white'
                    transform='translate(12.0181 11.8047)'
                  />
                </clipPath>
              </defs>
            </svg>

            <svg
              onClick={() =>
                window.open(
                  "https://www.instagram.com/goalmogulofficial/",
                  "_blank"
                )
              }
              width='35'
              height='36'
              viewBox='0 0 35 36'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='17.3321' cy='18.002' r='17.2676' fill='#DEF7FF' />
              <g clipPath='url(#clip0)'>
                <path
                  d='M20.0937 11.9805H14.5708C12.7733 11.9805 11.311 13.4428 11.311 15.2403V20.7633C11.311 22.5607 12.7733 24.023 14.5708 24.023H20.0938C21.8912 24.023 23.3535 22.5607 23.3535 20.7633V15.2403C23.3535 13.4428 21.8912 11.9805 20.0937 11.9805V11.9805ZM17.3323 21.2945C15.5166 21.2945 14.0395 19.8174 14.0395 18.0017C14.0395 16.186 15.5166 14.7089 17.3323 14.7089C19.148 14.7089 20.6251 16.186 20.6251 18.0017C20.6251 19.8174 19.148 21.2945 17.3323 21.2945ZM20.7038 15.4855C20.1672 15.4855 19.7308 15.0491 19.7308 14.5125C19.7308 13.9759 20.1672 13.5394 20.7038 13.5394C21.2404 13.5394 21.6769 13.9759 21.6769 14.5125C21.6769 15.0491 21.2404 15.4855 20.7038 15.4855Z'
                  fill='#42C0F5'
                />
                <path
                  d='M17.3324 15.416C15.9061 15.416 14.7456 16.5764 14.7456 18.0028C14.7456 19.4291 15.9061 20.5896 17.3324 20.5896C18.7588 20.5896 19.9192 19.4291 19.9192 18.0028C19.9192 16.5764 18.7588 15.416 17.3324 15.416Z'
                  fill='#42C0F5'
                />
                <path
                  d='M20.704 14.2461C20.5568 14.2461 20.437 14.3659 20.437 14.5131C20.437 14.6603 20.5568 14.7801 20.704 14.7801C20.8513 14.7801 20.9711 14.6604 20.9711 14.5131C20.9711 14.3658 20.8513 14.2461 20.704 14.2461Z'
                  fill='#42C0F5'
                />
              </g>
              <defs>
                <clipPath id='clip0'>
                  <rect
                    width='12.0425'
                    height='12.0425'
                    fill='white'
                    transform='translate(11.311 11.9805)'
                  />
                </clipPath>
              </defs>
            </svg>

            <svg
              onClick={() =>
                window.open(
                  "https://www.youtube.com/channel/UCGT2M4q4kdoH52uOfjPoJ_w",
                  "_blank"
                )
              }
              width='36'
              height='36'
              viewBox='0 0 36 36'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='17.7843' cy='18.002' r='17.2676' fill='#DEF7FF' />
              <g clipPath='url(#clip0)'>
                <path
                  d='M23.9493 14.8863C23.8007 14.3335 23.3648 13.8978 22.8122 13.7489C21.8025 13.4727 17.7637 13.4727 17.7637 13.4727C17.7637 13.4727 13.7251 13.4727 12.7154 13.7384C12.1733 13.8871 11.7269 14.3336 11.5782 14.8863C11.3125 15.8959 11.3125 17.9896 11.3125 17.9896C11.3125 17.9896 11.3125 20.094 11.5782 21.093C11.727 21.6457 12.1627 22.0814 12.7155 22.2303C13.7357 22.5066 17.7639 22.5066 17.7639 22.5066C17.7639 22.5066 21.8025 22.5066 22.8122 22.2408C23.3649 22.092 23.8007 21.6563 23.9495 21.1036C24.2151 20.094 24.2151 18.0003 24.2151 18.0003C24.2151 18.0003 24.2258 15.8959 23.9493 14.8863ZM16.4778 19.9239V16.0553L19.8363 17.9896L16.4778 19.9239Z'
                  fill='#42C0F5'
                />
              </g>
              <defs>
                <clipPath id='clip0'>
                  <rect
                    width='13.7629'
                    height='13.7629'
                    fill='white'
                    transform='translate(10.8892 11.2305)'
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>

        <div className='grow p33 copyRight'>
          <p>&copy; {new Date().getFullYear()} GoalMogul, Inc.</p>
        </div>
        <div className='p33 termsLink'>
          <ul>
            {/* <li>
            <a href='/'>GoalMogul Home</a>
          </li> */}
            <li>
              <a href='mailto:support@goalmogul.com'>Contact Support</a>
            </li>
            <li>
              <a href='/terms'>Terms of Service</a>
            </li>
            <li>
              <a href='/privacy'>Privacy Policy</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}
