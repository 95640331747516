import React from "react"
import "./Footer.scss"
import playstore from './../../assets/v6 wireframes/playStore.png'
import appstore from './../../assets/v6 wireframes/appStore.png'


/**
 * Footer component shared by all pages
 */
export default function Footer7dot1() {
  return (
    <footer>
 
      <div className="footerLinks">
        <div className="grow p33">
          <div>


          </div>
        </div>

        <div className='grow p33'>
          <p>&copy; {new Date().getFullYear()} GoalMogul, Inc.</p>
        </div>
        <div className="p33">
          <ul>
            {/* <li>
            <a href='/'>GoalMogul Home</a>
          </li> */}
            <li>
              <a href='mailto:support@goalmogul.com'>Contact Support</a>
            </li>
            <li>
              <a href='/terms'>Terms of Service</a>
            </li>
            <li>
              <a href='/privacy'>Privacy Policy</a>
            </li>
          </ul>
        </div>

      </div>

    </footer>
  )
}
