import React, { useEffect } from "react"
import queryString from "query-string"
import { useLocation, useHistory } from "react-router-dom"
import axios from "axios"
import PillButton from "./shared/PillButton"
import "./unsubscribe.scss"
import Mail from "../assets/Mail.png"
import Brand from "../assets/Logo.png"
const Unsubscribe = () => {
  const { search } = useLocation()
  const { id } = queryString.parse(search)
  const history = useHistory()
  const handleClick = () => {
    history.push("/resubscribe")
  }

  const submit = async () => {
    const res = await axios.get(
      "https://goalmogul-api-dev.herokuapp.com/sub/unsubscribe?id=" + id
    )
    console.log("responce===>", res)
    alert("You have successfully unsubscribed from the mailing list")
  }

  useEffect(() => {
    submit()
    localStorage.setItem("userid", id)
  })

  return (
    // <div
    //   style={{
    //     flex: 1,
    //     height: 600,
    //     margin: 30,
    //     padding: 5,
    //     borderRadius: 1,
    //     textAlign: "center",
    //   }}
    // >
    //   <h1>In order to re-subscribe kindly click on the following button</h1>
    //   <button
    //     style={{
    //       borderRadius: 7,
    //       border: 0,
    //       padding: 10,
    //       color: "white",
    //       justifyContent: "center",
    //       marginTop: 100,
    //       backgroundColor: "#42c0f5",
    //     }}
    //     onClick={handleClick}
    //   >
    //     Resubscribe
    //   </button>
    // </div>

    <div className='ExtraPages'>
      <div className='App'>
        <div className='app-header'>
          <div className='header-content'>
            <img src={Brand} alt='brand' />
          </div>
        </div>
        <div className='box-content Mail-content'>
          <div>
            <div className='mb'>
              <img src={Mail} alt='Mail' />
              <h5 className='box-h5'>You’ve unsubscribed</h5>
              <p>
                You'll no longer receive motivational emails from{" "}
                <a
                  href='mailto:Coach@goalmogul.com'
                  style={{ textDecoration: "none", fontWeight: 500 }}
                  target={"_blank"}
                >
                  Coach@goalmogul.com
                </a>
              </p>
            </div>
            {/* <h6>Manage other email preferences</h6> */}
          </div>
          <div className='card-footer123'>
            <hr />
            <p style={{ maxWidth: "100%", marginBottom: "16px" }}>
              Unsubcribed by accident?{" "}
              <a onClick={handleClick}>Subscribe again</a>
            </p>
          </div>
        </div>
        <div className='app-footer'>
          <p>GoalMogul Inc. © 2022</p>
        </div>
      </div>
    </div>
  )
}
export default Unsubscribe
