import React from "react"
import Step_1images1 from "../../assets/changes-images/step_1image1.png"
import Step_1images2 from "../../assets/changes-images/step_1image2.png"
import Step_2images1 from "../../assets/changes-images/step_2image1.png"
import Step_2images2 from "../../assets/changes-images/step_2image2.png"
import Step_3images1 from "../../assets/changes-images/step_3image1.png"
import Step_3images2 from "../../assets/changes-images/step_3image2.png"
import Step_4images1 from "../../assets/changes-images/step_4image1.png"
import Step_4images2 from "../../assets/changes-images/step_4image2.png"
import playstore from "./../../assets/v6 wireframes/playStore.png"
import appstore from "./../../assets/v6 wireframes/appStore.png"

type Props = {}

function HowItWorkMob({}: Props) {
  return (
    <div className='how-it-works-page hidePc'>
      <h2 className='heading-css'>How it Works</h2>
      <div className='howItWorksSec new-child-setting'>
        <div className='step_1'>
          <h2 className='heading-css change-style'>
            Step 1: Unleash <br /> AI-Powered <br /> Goal Mastery
          </h2>
          <p className='para-style-changes'>
            Define your goals <br /> Let AI create your personalized, <br />{" "}
            step-by-step roadmap to triumph
          </p>
        </div>
        <div className='step-1-images step-1Image'>
          <div>
            <img className='image1' src={Step_1images1} alt='Step_1images' />
          </div>
          <div>
            <img className='image1' src={Step_1images2} alt='Step_1images' />
          </div>
        </div>
      </div>
      <div className='howItWorksSec new-child-setting'>
        <div className='step-1-images step-2Image'>
          <div>
            <img className='image1' src={Step_2images1} alt='Step_1images' />
          </div>
          <div>
            <img className='image1' src={Step_2images2} alt='Step_1images' />
          </div>
        </div>
        <div className='step_2'>
          <h2 className='heading-css change-style'>
            Step 2: Meet <br /> the Right People in a <br /> Supportive
            Community
          </h2>
          <p className='para-style-changes'>
            Effortlessly share goals with friends & <br /> AI-recommended
            connections
          </p>
        </div>
      </div>
      <div className='howItWorksSec new-child-setting'>
        <div className='step_3'>
          <h2 className='heading-css change-style'>
            Step 3: Say Goodbye to <br /> Procrastination
          </h2>
          <p className='para-style-changes'>
            Schedule custom, automated reminder <br /> nudges until tasks are
            'Marked as Done'
            <br />
            <br />
            Send (omnichannel) text, voice and video <br /> reminders via SMS,
            Email, Direct Message, and <br /> push notification
            <br />
            <br />
            Never fall off track again
          </p>
        </div>
        <div className='step-1-images step-3Image'>
          <div>
            <img className='image1' src={Step_3images1} alt='Step_1images' />
          </div>
          <div>
            <img className='image1' src={Step_3images2} alt='Step_1images' />
          </div>
        </div>
      </div>
      <div className='howItWorksSec new-child-setting'>
        <div className='step-1-images step-4Image'>
          <div>
            <img className='image1' src={Step_4images1} alt='Step_1images' />
          </div>
          <div>
            <img className='image1' src={Step_4images2} alt='Step_1images' />
          </div>
        </div>
        <div className='step_4'>
          <h2 className='heading-css change-style'>
            Step 4: Help Your Community <br /> Crush It with the <br /> Ultimate
            Accountability Tool
          </h2>
          <p className='para-style-changes'>
            Effortlessly motivate, track, and nudge your peers <br /> with an
            amazingly simple & intuitive dashboard <br />
            <br />
            Get real-time notifications as they make progress & <br /> goals are
            completed
          </p>
        </div>
      </div>
      <div className='appButtonsDiv'>
        <div className='AppDownloadDiv extra-css'>
          {/* <div className={styles.buttonDown} style={{ backgroundImage: `url(${playstore})` }} > </div>
                  <div className={styles.buttonDown} style={{ backgroundImage: `url(${appstore})` }} > </div> */}
          <a
            target='_blank'
            href='https://play.google.com/store/apps/details?id=com.goalmogul.goalmogulbeta'
          >
            <img className='buttonImage' src={playstore} />
          </a>
          <a
            target='_blank'
            href='https://apps.apple.com/us/app/goalmogul/id1441503669'
          >
            <img className='buttonImage' src={appstore} />
          </a>

          {/* <h3 className={styles.v7ComingSoon}>COMING SOON</h3> */}
        </div>
      </div>
    </div>
  )
}

export default HowItWorkMob
