import moment from "moment"

const API_BASE_URL_OTHER = "https://api.goalmogul.com"
// const API_BASE_URL_OTHER = "https://api.d.goalmogul.com"

export function getComments(params: { [index: string]: string }) {
  try {
    return get(`/api/secure/feed/comment`, params)
  } catch (e) {
    throw e
  }
}

export function subToNewsLetter(email: string) {
  try {
    return post(
      `/api/pub/user/user-newsletter`,
      JSON.stringify({ email: email })
    )
  } catch (e) {
    throw e
  }
}

export async function getSubscribersList() {
  try {
    const response = await fetch(
      `${API_BASE_URL_OTHER}/api/pub/user/user-newsletter/get-list`
    )
    const respJSON = await response.json()
    return respJSON
  } catch (e) {
    throw e
  }
}

export function getGoalPub(params: { [index: string]: string }) {
  try {
    return get(`/api/pub/goal/${params.publicIdentifier}`, params)
  } catch (e) {
    throw e
  }
}

export function getGoal(params: { [index: string]: string }) {
  try {
    return get(`/api/secure/goal`, params)
  } catch (e) {
    throw e
  }
}

export function enableGoalPublicURL(params: { [index: string]: string }) {
  try {
    const body = JSON.stringify({
      ...params,
      updates: {
        isPublicURLEnabled: true,
      },
    })
    return put(`/api/secure/goal/`, body, params.token)
  } catch (e) {
    throw e
  }
}

export function getGoalUpdates(params: { [index: string]: string }) {
  try {
    return get(`/api/secure/feed/post/${params.goalId}/updates`, params)
  } catch (e) {
    throw e
  }
}

export function getUserGoals(params: { [index: string]: string }) {
  try {
    return get(`/api/secure/goal/user`, params)
  } catch (e) {
    throw e
  }
}

export function accountVerification(inputs: object) {
  const body = JSON.stringify(inputs)
  try {
    return post("/api/secure/user/account/verification", body)
  } catch (e) {
    throw e
  }
}
export function updateAccount(inputs: object, token: string, section: string) {
  const body = JSON.stringify(inputs)
  try {
    return put(`/api/secure/user/account${section}`, body, token)
  } catch (e) {
    throw e
  }
}

export function getProfile(params: { [index: string]: string }) {
  try {
    return get("/api/secure/user/profile", params)
  } catch (e) {
    throw e
  }
}
/**
 * Get invitor info
 * @param params[inviteCode] Given a invite code, get the invitor's info
 */
export function getInvitor(params: { [index: string]: string }) {
  try {
    return get("/api/pub/user", params)
  } catch (e) {
    throw e
  }
}

/**
 * Verify account email or phone number
 * @param inputs[token] in email verification link or texted to phone number
 * @param inputs[for] email | phone
 */
export function verification(inputs: Object) {
  const body = JSON.stringify(inputs)
  try {
    return post("/api/pub/user/verification", body)
  } catch (e) {
    throw e
  }
}

/**
 * Sets the password
 * @param inputs[password] new password
 * @param inputs[token] in reset password email link
 */
export function setPassword(inputs: Object) {
  const body = JSON.stringify(inputs)
  try {
    return post("/api/pub/user/password/reset", body)
  } catch (e) {
    throw e
  }
}

/**
 * Request a pw reset link
 * @param inputs[email] address to recieve pw reset link
 * @param inputs[phone] number to recieve pw reset link
 */
export function pwResetRequest(inputs: Object) {
  const body = JSON.stringify(inputs)
  try {
    return post("/api/pub/user/password/reset-request", body)
  } catch (e) {
    throw e
  }
}

/**
 * Login
 * @param inputs[email] address to login with
 * @param inputs[phone] number to login with
 * @param inputs[password] user pw
 */
export function login(inputs: Object) {
  const body = JSON.stringify(inputs)
  try {
    return post("/api/pub/user/authenticate", body)
  } catch (e) {
    throw e
  }
}

/**
 * Register an account
 * @param inputs[name] user's name
 * @param inputs[email] user's email
 * @param inputs[phone] user's phone number. Optional.
 * @param inputs[password] user's password
 * @param inputs[inviteCode] inviteCode stored in local storage. Optional.
 */
export function register(inputs: Object) {
  const body = JSON.stringify(inputs)
  try {
    return post("/api/pub/user/", body)
  } catch (e) {
    throw e
  }
}

export async function post(path: string, body: string) {
  try {
    const resp = await fetch(`${API_BASE_URL_OTHER}${path}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    })
    const respJson = await resp.json()
    if (!resp.ok) {
      throw respJson.message
    }
    return respJson
  } catch (e) {
    throw e
  }
}

export async function get(path: string, params: { [index: string]: string }) {
  const url = new URL(`${API_BASE_URL_OTHER}${path}`)
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  )
  try {
    const resp = await fetch(url.toString(), { method: "GET" })
    const respJson = await resp.json()
    if (!resp.ok) {
      throw respJson.message
    }
    return respJson
  } catch (e) {
    throw e
  }
}

export async function put(path: string, body: string, token: string) {
  try {
    const resp = await fetch(`${API_BASE_URL_OTHER}${path}`, {
      method: "PUT",
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
      body: body,
    })
    const respJson = await resp.json()
    if (!resp.ok) {
      throw respJson.message
    }
    return respJson
  } catch (e) {
    throw e
  }
}

export async function getReminder(key: string) {
  try {
    const response = await fetch(
      `${API_BASE_URL_OTHER}/api/pub/reminder/reminder_by_url?key=${key}`
    )
    const respJSON = await response.json()
    return respJSON
  } catch (e) {
    throw e
  }
}

export async function acceptReminder(key: string) {
  try {
    const response = await fetch(
      `${API_BASE_URL_OTHER}/api/pub/reminder/accept_reminder_by_url?key=${key}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    const respJSON = await response.json()
    return respJSON
  } catch (e) {
    throw e
  }
}

export async function markDoneReminder(key: string) {
  try {
    const response = await fetch(
      `${API_BASE_URL_OTHER}/api/pub/reminder/mark_as_done_reminder_by_url?key=${key}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    const respJSON = await response.json()
    return respJSON
  } catch (e) {
    throw e
  }
}
const weekData: Record<string, string> = {
  "1": "first",
  "2": "second",
  "3": "third",
  "4": "fourth",
  "5": "fifth",
}

const dayNames: Record<string, string> = {
  "0": "Sunday",
  "1": "Monday",
  "2": "Tuesday",
  "3": "Wednesday",
  "4": "Thursday",
  "5": "Friday",
  "6": "Saturday",
}

const monthData: Record<string, string> = {
  "1": "January",
  "2": "February",
  "3": "March",
  "4": "April",
  "5": "May",
  "6": "June",
  "7": "July",
  "8": "August",
  "9": "September",
  "10": "October",
  "11": "November",
  "12": "December",
}

interface ReminderProps {
  type: string
  startDate: string
  everyWeek: string
  weeklySelectedDays: string[] | undefined
  monthlySelectedDays: string[] | undefined
  everyMonth: string
  customEvery: string
  everyMonthWeekDay: string
  monthlyWeekDay: string
  customEveryUnit: string
  exceptionDates: string[] | undefined
  exceptionTimes: string[] | undefined
  everyYear: string
  yearlyWeek: string
  yearlyDay: string
  yearlyMonth: string
}

interface User {
  user: {
    name?: string
  }
}

interface ExceptionDate {
  fromDate: Date
  toDate: Date
}

interface ExceptionTime {
  fromTime?: Date
  toTime?: Date
}

interface CustomReminderOptions {
  type: string
  startDate: Date
  everyWeek: string
  weeklySelectedDays?: number[]
  monthlySelectedDays?: number[]
  everyMonth: string
  customEvery: string
  everyMonthWeekDay: string
  monthlyWeekDay: number
  customEveryUnit: string
  exceptionDates?: ExceptionDate[]
  exceptionTimes?: ExceptionTime[]
  everyYear: string
  yearlyWeek: string
  yearlyDay: number
  yearlyMonth: string
}

const variableMethods = {
  firstNameCaps(user: User): string | undefined {
    if (user.user.name) {
      let path = user?.user?.name
        ?.split(/(\s+)/)
        .filter((e) => e.trim().length > 0)
      return path ? path[0].toUpperCase() : undefined
    } else return
  },
  firstname(user: User): string | undefined {
    if (user.user.name) {
      let path = user?.user?.name
        ?.split(/(\s+)/)
        .filter((e) => e.trim().length > 0)
      return path ? path[0] : undefined
    } else return
  },
  getFirstName(name: string): string | null {
    let path = name?.split(/(\s+)/).filter((e) => e.trim().length > 0)
    return path ? path[0] : null
  },
  createArray(number: number, interval = 1): string[] {
    let tempArray: string[] = []
    for (let i = 1; i <= number; i++) {
      if (i % interval === 0) {
        tempArray.push(i.toString())
      }
    }
    return tempArray
  },
  getReminderMinutes(): string[] {
    return ["5", "10", "15", "20", "30"]
  },
  millisToMinutesAndSeconds(millis: number): string {
    const minutes = Math.floor(millis / 60000)
    const seconds = ((millis % 60000) / 1000).toFixed(0)
    return `${minutes}:${(seconds < "10" ? "0" : "") + seconds}`
  },
  numberSuffix(number: any): string {
    const d = Number(number)
    if (d > 3 && d < 21) return `${d}th`
    switch (d % 10) {
      case 1:
        return `${d}st`
      case 2:
        return `${d}nd`
      case 3:
        return `${d}rd`
      default:
        return `${d}th`
    }
  },
  createCurrentDateWith(hours: number, minutes: number, initDate?: Date): Date {
    const date = initDate ? new Date(initDate) : new Date()
    date.setHours(hours, minutes)
    return date
  },
  getSelectedYearlyText(week: string, day: number, month: string): string {
    return `${weekData[week] || "first"} ${dayNames[day] || "Month"} in ${
      monthData[month] || "January"
    }`
  },
  convertDateToAmOrPm(date: Date, isAm: boolean): Date {
    const newDate = moment(date)

    if (newDate.format("A") === "AM" && isAm) {
      return newDate.toDate()
    } else if (newDate.format("A") === "AM" && !isAm) {
      return newDate.clone().add(12, "hours").toDate()
    } else if (newDate.format("A") === "PM" && !isAm) {
      return newDate.toDate()
    } else if (newDate.format("A") === "PM" && isAm) {
      return newDate.clone().subtract(12, "hours").toDate()
    }

    // Default return statement (can be adjusted based on your logic)
    return newDate.toDate()
  },

  capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1)
  },
  getExceptDateText(exceptionDates: ExceptionDate[] | undefined): string {
    if (Array.isArray(exceptionDates)) {
      return exceptionDates
        .map((item) => {
          if (
            moment(item.fromDate).isValid() &&
            moment(item.toDate).isValid()
          ) {
            const fromDate = moment(item.fromDate).format("MMMM DD, YYYY")
            const toDate = moment(item.toDate).format("MMMM DD, YYYY")
            return `${fromDate} to ${toDate}`
          }
          return ""
        })
        .join("; ")
    }
    return ""
  },

  getExceptTimeText(exceptionTimes: ExceptionTime[] | undefined): string {
    if (Array.isArray(exceptionTimes)) {
      return exceptionTimes
        .map((item) => {
          const fromTime = moment(item?.fromTime)
          const toTime = moment(item?.toTime)
          if (fromTime && item.fromTime) {
            const fromTimeText = fromTime.format("hh:mma")
            const toTimeText = toTime.format("hh:mma")
            return `${fromTimeText} to ${toTimeText}`
          }
          return ""
        })
        .join("; ")
    }
    return ""
  },
  getReminderTimeForCustom(options: CustomReminderOptions): string {
    // Function implementation...
    return ""
  },
  isValidEmail(email: string): boolean {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
    return reg.test(email)
  },
  isValidPhoneNumber(number: string): boolean {
    const removeSpaceNumber = number.replace(/ /g, "")
    const reg = /^(\+?\d{1,3}\s?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    return reg.test(removeSpaceNumber)
  },
}

export const getReminderTimeForCustom = ({
  type,
  startDate,
  everyWeek,
  weeklySelectedDays,
  monthlySelectedDays,
  everyMonth,
  customEvery,
  everyMonthWeekDay,
  monthlyWeekDay,
  customEveryUnit,
  exceptionDates,
  exceptionTimes,
  everyYear,
  yearlyWeek,
  yearlyDay,
  yearlyMonth,
}: ReminderProps): string => {
  const date = moment(startDate)
  const tempMonthlyDays = Array.isArray(monthlySelectedDays)
    ? [...monthlySelectedDays]
    : []
  const selectedMonthDays = tempMonthlyDays
    .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
    .map((item) => variableMethods.numberSuffix(item))
    .join(", ")
  //@ts-ignore
  const exceptDateText = Array.isArray(exceptionDates)
    ? // @ts-ignore
      variableMethods.getExceptDateText(exceptionDates)
    : ""
  const finalExceptDateText = exceptDateText
    ? ` except from ${exceptDateText}`
    : ""
  //@ts-ignore
  const exceptTimeText = Array.isArray(exceptionTimes)
    ? // @ts-ignore
      variableMethods.getExceptTimeText(exceptionTimes)
    : ""
  const finalExceptTimeText = exceptTimeText
    ? ` except from ${exceptTimeText}`
    : ""

  switch (type) {
    case "never":
      return `On ${date.format("MMMM DD, YYYY | hh:mm A")}`
    case "daily":
      return `Daily at ${date.format("hh:mm A")}`
    case "weekly":
      const selectedWeekDays = Array.isArray(weeklySelectedDays)
        ? weeklySelectedDays.map((item: any) => dayNames[item]).join(", ")
        : ""
      return `Every ${
        everyWeek !== "1" ? `${everyWeek} weeks on ` : ""
      }${selectedWeekDays} at ${date.format("hh:mm A")}`
    case "monthly":
      if (everyMonthWeekDay && monthlyWeekDay) {
        return `Every ${everyMonth !== "1" ? everyMonth + " " : ""}month${
          everyMonth !== "1" ? "s" : ""
        } on the ${weekData[everyMonthWeekDay]} ${
          dayNames[monthlyWeekDay]
        } at ${date.format("hh:mm A")}`
      }
      return `Every ${everyMonth !== "1" ? everyMonth + " " : ""}month${
        everyMonth !== "1" ? "s" : ""
      } on the ${selectedMonthDays} at ${date.format("hh:mm A")}`
    case "yearly":
      return `Every ${everyYear !== "1" ? everyYear + " " : ""}year${
        everyYear !== "1" ? "s" : ""
      } on ${date.format("MMMM DD [at] hh:mm A")}`
    case "custom":
      switch (customEveryUnit) {
        case "day":
          if (customEvery === "1") {
            return `Daily at ${date.format("hh:mm A")}${finalExceptDateText}`
          }
          return `Every ${customEvery} days at ${date.format(
            "hh:mm A"
          )}${finalExceptDateText}`
        case "week":
          const selectedWeekDays = Array.isArray(weeklySelectedDays)
            ? weeklySelectedDays.map((item) => dayNames[item]).join(", ")
            : ""
          return `Every ${
            customEvery !== "1" ? `${customEvery} weeks on ` : ""
          }${selectedWeekDays} at ${date.format(
            "hh:mm A"
          )} ${finalExceptDateText}`
        case "hour":
          return `Every ${customEvery !== "1" ? customEvery + " " : ""}hour${
            customEvery !== "1" ? "s" : ""
          } ${finalExceptTimeText}`
        case "minute":
          return `Every ${customEvery} minutes ${finalExceptTimeText}`
        case "month":
          if (everyMonthWeekDay && monthlyWeekDay) {
            return `Every ${customEvery !== "1" ? customEvery + " " : ""}month${
              customEvery !== "1" ? "s" : ""
            } on the ${weekData[everyMonthWeekDay]} ${
              dayNames[monthlyWeekDay]
            } at ${date.format("hh:mm A")}`
          }
          return `Every ${customEvery !== "1" ? customEvery : ""} month${
            customEvery !== "1" ? "s" : ""
          } on the ${selectedMonthDays} at ${date.format("hh:mm A")}`
        case "year":
          return `Every ${customEvery !== "1" ? customEvery + " " : ""}year${
            customEvery !== "1" ? "s" : ""
          } on the ${variableMethods.getSelectedYearlyText(
            yearlyWeek,
            //@ts-ignore
            yearlyDay,
            yearlyMonth
          )} at ${date.format("hh:mm A")}`
        default:
          return "Reminder: "
      }
    default:
      return "Reminder: "
  }
}
